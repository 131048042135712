//add rules for responsive action in nivo sliders//
.slider-wrapper.theme-default {
  max-width: 100%;
}
.theme-default .nivoSlider img  {
  max-width: 100%;
}
.theme-default .nivo-caption {
  a {
  border:0;
  }
  .showhide {
    position: absolute;
    right:0rem;
    top:0rem;
    border:0;
    margin: 0;
  }

}
.notsee {
  display:none;
}
.nivoDescription {
  height:2.7rem;
  h3 {
    margin-right:6rem;
  }
}

.showMore {
  .nivoDescription {
    height:auto
  }
  .notsee {
    display:inline;
  }
  .see {
    display:none;
  }

  .nivo-caption  {
    padding: 0 10px;
  }
}

.nivoDescription {
  overflow: scroll;
}
