/*
 * 
 * Pagination
 * 
 */

ul.pagination{
    border-top:4px $light-gray solid;
    margin-top:1rem;
    padding-top:1rem;
    float:left;
    width:100%;
    clear: both;
    li {
        font-size: 1.3rem;
        font-weight: 400;
        a ,span{
                display:block;
                float: left;
                border:$dark-gray 1px solid ;
                background: $black;
                color:$white;
                padding: 0.1rem 0.4rem;
                margin-top:0.4rem;

        }
        &.active {
            a ,span{
            border:$dark-gray 1px solid ;
            background: $red;
            color:$white;

        }

        }
        &.disabled {
            padding:0px;
            padding-right: 0.625rem;
            padding-left: 0.625rem;}
    }

}
