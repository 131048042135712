/*
 * Various stuff that don't fit elsewhere
 */

/*
 * Some styling for this JS component stuff (read more on 'js/jquery/addons.js')
 */
#watch-for-current-mquery{
    padding: 0.0rem 0.5rem;
    position: absolute;
    top: 10px;
    right: 35px;
    font-size: 14px;
    font-weight:bold;
    line-height:1.4;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid $red;
    z-index: 9000;

    small{
        display: block;
    }
}


// Shortcut relative position
.relative-position {
    position: relative;
}

// Remove all margins
.unmarged {
    margin: 0 !important;
}
// Remove all padding
.unpad{
    padding: 0 !important;
}
// Remove all padding
.minimize{
    text-transform: inherit;
    &:first-letter{
        text-transform: uppercase;

    }
}



// Sometime we need to separate an element from an another adjacent element
.divider:not(li) {
    margin-bottom: 2rem !important;

    &.tiny-divide{
        margin-bottom: 1rem !important;
    }

    &.big-divide{
        margin-bottom: 3rem !important;
    }
}

/* Vertical spaces */

//by margin
.vertmarge {
    margin-top: 1rem;
    margin-top: 1rem;
}

//larger row
.vertspace {
    padding-bottom: 1rem;
    padding-top: 1rem;
}

//larger row X2
.vertspaceX2 {
    padding-bottom: 2rem;
    padding-top: 2rem;
}

.vertspaceTopX2 {
    padding-top: 2rem;
}

//larger row X3
.vertspaceX3 {
    padding-bottom: 3rem;
    padding-top: 3rem;
}

//font size
.sizeX2 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.4rem;
    strong {
        font-weight: 400;
    }
}

/*
 * Vertical spaces around element
 */
.space-around{
    padding: .7rem 0;

    @media #{$medium-up} {
        padding: 1.2rem 0;
    }
    @media #{$large-up} {
        padding: 1.5rem 0;
    }
    @media #{$xlarge-up} {
        padding: 2rem 0;
    }

    // Tiny spaces
    &.tiny-space{
        padding: 0.75rem 0;

        @media #{$medium-up} {
            padding: 1rem 0;
        }
    }

    // Medium spaces
    &.medium-space{
        padding: 1rem 0;

        @media #{$medium-up} {
            padding: 1.5rem 0;
        }
    }

    // Wider spaces
    &.wide-space{
        padding: 1.5rem 0;

        @media #{$medium-up} {
            padding: 2.5rem 0;
        }
        @media #{$large-up} {
            padding: 4rem 0;
        }
    }

    // For only top or bottom space
    &.top-only{
        padding-bottom: 0;
    }
    &.bottom-only{
        padding-top: 0;
    }
}

// Put a triangle pointing to bottom, horizontally centered and at
// bottom of element
$bottom-triangle-size: rem-calc(20px) !default;
.bottom-triangle{
    position: relative;
    &:after {
        content: "";
        position: absolute;
        bottom: -$bottom-triangle-size;
        left: 50%;
        @include translate(-50%, 0%);
        width: 0;
        height: 0;
        border-left: $bottom-triangle-size solid transparent;
        border-right: $bottom-triangle-size solid transparent;
        border-top: $bottom-triangle-size solid $black;
    }
}

// contentBlocks
.more {
    text-align: center;
    a {
        text-transform: uppercase;
        padding: 10px 30px;
        font-weight: 700;
        font-size: 12px;
    }
    button, .button {
        background-color: rgba(0, 0, 0, 0);
        border-style: solid;
        border-width: 1px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        &:hover {
        }
    }
}


/*
 * Cardbox, like a panel but with high visual title
 */
.cardbox{
    &.marged{
        margin-top: 1em !important;
        margin-bottom: 1em !important;

        @media #{$medium-up} {
            margin-top: 1.5em !important;
            margin-bottom: 2em !important;
        }
        @media #{$large-up} {
            margin-top: 2em !important;
            margin-bottom: 3em !important;
        }
    }

    .head,
    .content{
        padding: 0.75rem 1.5rem;
    }

    .head{
        margin-bottom: 1px;
        p{
            margin: 0;
        }
    }

    .content{
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

        a:not(.button){
            color: inherit;
            text-decoration: underline;
        }
    }

}
//*topFlag */

#headpage {
    position: relative;
    float:left;
    width:100%;
    &:before {
        white-space: nowrap;
        position:absolute;
        content: attr(data-title);
        font-size:12rem;
        top:-8rem;
        left:23rem;
        z-index: -1;
        opacity: 0.075;
        text-transform: uppercase;
        font-weight: 600;
        max-width: calc(100vw - 16px - 23rem);
        overflow: hidden;
        display:block;
    }

    &:after{
        content:'';
        font-size:14rem;
        position: absolute;
        top:-10rem;
        left:8.5rem;
        z-index: -1;
        opacity: 0.075;
        font-family: icomoon;
    }


    &.expositions_ico:after {
        content:'';
    }
    &.visites_ico:after {
        content:'';
    }
    &.collections_ico:after {
        content:'';
    }
    &.reperes_ico:after {
        content:'';
    }
    &.outils_ico:after {
        content:'';
    }
}
/*leftFlag %*/

.leftFlag {

    @include breakpoint(xlarge up) {
        &:before {
            position:fixed;
            top:250px;
            left:0;
            content:url('/static/images/theme/leftFlag.png');
            z-index: -1;
        }

    }
}


.is-accordion-submenu-parent {
    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        border: inset 5px;
        border-color: #dd3333 transparent transparent;
        border-top-style: solid;
        border-bottom-width: 0;
        right: 5px;
        margin-top: -2rem;
        float:right;
    }
}


//bighr
.bighr { border-width: 6px; border-color: $light-gray;margin-left:0;margin-right:0;max-width: none}


// panel
.panel {
    border-radius: 2px;
    float:left;
    width:100%;

}