@import 'components/expo/header';
@import 'components/expo/dotnav';
.unpadded {
    padding: 0 !important;
}

.row-larger {
    max-width: $larger-row;
}

.padding1 {
    padding: 1rem;
}

.v-bottom-large {
    padding-bottom: 6.5rem;
}

.expo_content {
    background: $expo_white;
    &.blackcontent {
        background: $black;
    }
    header {
        background: $black;
        display: block;
        @media #{$medium-up} {
            display: block;
        }
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 0rem;
            margin-top: 0rem;
            @media #{$large-up} {
                margin-bottom: 0rem;
                margin-top: 0rem;
            }
            &.underline {
                border-bottom: 2px $dark-gray2 solid;
            }
            * {
                margin-bottom: 0;
                font-size: 0.8rem;
                @media #{$medium-only} {
                    font-size: 0.8rem;
                }
                @media #{$large-only} {
                    font-size: 1.3rem;
                }
                @media #{$xlarge-up} {
                    font-size: 1.5rem;
                }
            }
            [class*=" icon-"] {
                margin: 0 0.25rem;
                font-size: 1.4rem;
                &:before {
                    font-size: 1.4rem;
                }
            }
        }
        h1 {
            font-size: 2.25rem;
            font-weight: 600;
        }
        h3 {
            font-weight: 700;
        }
    }
}

.block {
    &.black {
        background: $black;
        * {
            color: $white;
        }
    }
    &.gray {
        background: darken($gray, 20%);
        * {
            color: $white;
        }
    }
    &.white {
        background: $highlight-gray2;
        * {
            color: $black;
        }
    }
    &.whiter {
        background: $expo_white;
        * {
            color: $black;
        }
    }
}

.content-box {
    &.black {
        background: $black;
        a {
            color: $red;
        }
    }
}

///footer
.expo_body {
    footer {
        margin: 0;
    }
    .headerContainer {
        background-image: url("/static/images/theme/ban-expo.png");
        background-repeat: no-repeat;
        background-position: center right;
        background-size: contain;
        #header.intern {
            background: transparent;
        }
        .top-bar {
            background: transparent;
        }
    }
}

#contentexpo_container {
    display: flex;
    position: relative;
    height: auto;
    margin-top: -6rem;
    flex-direction: column;
    align-items: center;
    @media #{$large-up} {
        align-items: flex-start;
        flex-direction: row;
    }
}

.expo_content {
    aside,
    main {
        padding-top: 2.5rem;
    }
    aside {
        position: relative;
        top: 0;
        white-space: nowrap;
        @media #{$large-up} {
            position: sticky;
        }
    }
}

.gobackside {
    position: sticky;
    display: block;
    height: 90vh;
    >div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        transform-origin: center;
        font-size: 1.5rem;
        font-family: 'epilogue';
        font-weight: 600;
        text-transform: uppercase;
    }
}

#navbar-thematics-left {
    position: sticky;
    top: 0;
    ul {
        background: $black;
        display: flex;
        flex-direction: column;
        margin: 0;
        border: 2px $black solid;
        //margin-bottom: 0.5em;
        li {
            display: flex;
            border-bottom: 2px solid $black;
            &:last-child {
                border-bottom: 0;
            }
        }
        a {
            display: block;
            float: left;
            width: 100%;
            background: #fff;
            padding: 0.5rem;
            font-family: 'varta';
            text-transform: uppercase;
            text-align: center;
            font-size: 0.875rem;
            line-height: 1rem;
            color: $black;
            &:hover {
                color: $red;
            }
        }
    }
}

#items-expo,
#items-thems {
    float: none;
    width: 95%;
    @media #{$xlarge-up} {
        float: right;
        width: 90%;
    }
    &.list-chap {
        width: 100%;
        clear: both;
        float: left;
        padding-bottom: 5rem;
    }
    .gridsizer {
        width: calc(100% / 2);
        @media #{$large-up} {
            width: calc(100% / 3);
        }
        @media #{$xlarge-up} {
            width: calc(100% / 4);
        }
    }
}

#items-expo {
    width: 90%;
    margin: 0%;
    @media #{$xlarge-up} {
        float: right;
        width: 90%;
        margin: auto;
    }
}

#items-thems {
    width: 100%;
    position: relative;
    top: 0rem;
    .card-expo figcaption a {
        color: $black
    }
}

.inExpo {
    font-size: 66%;
    color: black !important;
    display: inline-block;
    clear: both;
    * {
        color: black !important;
    }
    float: left;
}

.articlegoto {
    display: block;
    margin-top: 4rem;
    float: left;
    ///
    width: 100%;
    //
    //&:hover {
    //  .card-expo.bigcard {
    //    .info-exposition-right-down {
    //      top:0;
    //    }
    //  }
    //}
    h1 {
        transition: all 0.2s;
        color: $white !important;
        text-shadow: 0 0 0px rgba(0, 0, 0, 0.5);
    }
    &:hover {
        h1 {
            transition: all 0.2s;
            color: $red !important;
            text-shadow: 0 0 40px rgba(0, 0, 0, 1), 0 0 4px rgba(0, 0, 0, 1);
        }
    }
    @media #{$small-only} {
        .thumb {
            position: relative;
            height: 30vw;
            min-height: 120px;
            max-height: 160px;
            width: 100%;
            img {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    @media #{$medium-only} {
        .thumb {
            position: relative;
            height: 20vw;
            max-height: 320px;
            width: 100%;
            img {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.listexpos {
    padding-bottom: 4rem;
    float: left;
    width: 100%;
}

.card-expo {
    &:hover {
        color: $red !important;
        ;
        figcaption {
            color: $red !important;
            ;
            a {
                color: $red !important;
            }
        }
    }
    width: calc(100% / 2 - 16px);
    font-family: "Varta";
    padding-bottom:1rem;
    position: relative;
    @media #{$large-up} {
        width: calc(100% / 3 - 16px);
    }
    @media #{$xlarge-up} {
        width: calc(100% / 4 - 16px);
    }
    &.bigcard {
        width: 100%;
        .info-exposition-right-down {
            position: absolute;
            .infoexpo {
                margin: 3rem;
                max-height: calc(100% - 3rem);
                display: inline-block;
                p {
                    text-align: justify;
                }
                @media #{$small-only} {
                    margin: 1rem;
                    max-height: calc(100% - 3rem);
                    display: inline-block;
                    scroll-behavior: smooth;
                    overflow-y: scroll;
                    transform: translate(0, 1rem);
                }
                @media #{$medium-only} {
                    margin: 1rem;
                    max-height: calc(100% - 3rem);
                    display: inline-block;
                    scroll-behavior: smooth;
                    overflow-y: scroll;
                    transform: translate(0, 1rem);
                }
                @media #{$medium-up} {
                    p {
                        font-size: 0.95rem;
                        line-height: 1.2em;
                    }
                }
                @media #{$large-up} {
                    p {
                        font-size: 1.1rem;
                        line-height: 1.25em;
                    }
                }
                @media #{$xlarge-up} {
                    p {
                        font-size: 1.3rem;
                        line-height: 1.25em;
                    }
                }
            }
        }
        .vid-expo {
            background: $black;
            color: $white;
            font-size: 1.125rem;
            display: flex;
            align-items: center;
            >* {
                color: $white;
                font-family: "inter";
                font-weight: 600;
            }
            &.on-black {
                >*,
                >* * {
                    color: $white;
                }
            }
        }
        figcaption {
            bottom: auto;
            top: 50%;
            transform: translate(0, -50%);
            background: transparent;
            h1 {
                font-size: 1.625rem;
                text-transform: none;
                font-weight: 900;
                line-height: 1.1;
                margin-top: 1.375rem;
                margin-bottom: 0;
                margin-left: 0.75rem;
                @media #{$medium-up} {
                    font-size: 2rem;
                }
                @media #{$large-up} {
                    font-size: 2.75rem;
                }
                @media #{$xlarge-up} {
                    font-size: 3.4rem;
                }
            }
        }
        .info-exposition-right-down {
            position: absolute;
            top: 100%;
            height: 100%;
            width: 100%;
            background: $highlight-gray2;
            color: $black;
            transition: all 0.5s;
            display: flex;
            align-items: center;
        }
        .thumb {
            position: relative;
            img {
                object-fit: cover;
            }
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.38);
                height: 100%;
                width: 100%;
                content: "";
            }
        }
        .info-exposition {
            padding: 0.875rem 0 1.8rem;
            font-family: 'inter';
            border-bottom: 1px solid $dark-gray2;
            .auth-expo {
                font-size: 0.875rem;
                color: $white;
                width: 100%;
                font-weight: 600;
                @media #{$medium-up} {
                    font-size: 1.125rem;
                }
            }
            .date-expo,
            .date-expo * {
                font-size: 0.875rem;
                color: $kasi-gray !important;
                flex-wrap: nowrap;
                white-space: nowrap;
                font-weight: 500;
                @media #{$medium-up} {
                    font-size: 0.75rem;
                }
                span {
                    white-space: nowrap
                }
            }
        }
    }
    &.smallcard {
        figcaption {
            width: calc(100% - 1.125rem);
            box-shadow: -4px 6px rgba(0, 0, 0, 0.4);
        }
        .info-exposition {
            width: 100%;
            height: 1.5rem;
            .auth-expo {
                overflow: hidden;
                font-size: 0.75rem;
            }
        }
    }
    figure {
        padding: 0;
        margin: 0;
        position: relative;
        overflow: hidden;
        img {
            width: 100%;
            border: 1px solid rgba($white, 0.9);
        }
    }
    figcaption {
        position: absolute;
        bottom: 1rem;
        background: $expo_white;
        font-family: "Inter";
        font-weight: 800;
        color: $black;
        padding: 0.125rem;
        font-size: 0.75rem;
        @media #{$medium-up} {
            font-size: 0.825rem;
        }
        a {
            color: $black;
        }
    }
    .vid-expo {
        position: absolute;
        top: 0;
        left: 0;
        background: $expo_white;
        font-size: 0.875rem;
        font-family: "Varta";
        z-index: 10;
        >* * {
            color: $black;
        }
        >* {
            color: $black;
            padding: 0.125rem 0.75rem;
            display: flex;
            align-items: center;
            i {
                margin-bottom: 0.1rem;
            }
            span {
                margin: 0.4rem;
                margin-bottom: 0.2rem;
                margin-left: 0.2rem;
                line-height: 1;
                font-size: 0.75rem;
                @media #{$medium-up} {
                    font-size: 0.825rem;
                }
                @media #{$medium-up} {
                    font-size: 0.825rem;
                }
                i {
                    font-size: 0.75rem;
                    @media #{$medium-up} {
                        font-size: 0.825rem;
                    }
                    @media #{$medium-up} {
                        font-size: 0.825rem;
                    }
                }
            }
        }
    }
    &.on-black {
        figcaption {
            background: $black;
            a {
                color: $white;
            }
        }
        .vid-expo {
            background: $black;
            color: $white;
        }
        figure img {
            border: 1px solid rgba($white, 0.9)
        }
        .vid-expo>*,
        .vid-expo>* * {
            color: $white;
        }
    }
    &:not(.on-black) {
        figcaption {
            a {
                color: $black;
            }
        }
        .vid-expo {
            color: $black;
        }
        figure img {
            border: 1px solid rgba(#9F9F9F, 0.9)
        }
    }
    .info-exposition {
        padding: 0.125rem;
        @media #{$medium-up} {
            display: flex;
            flex-wrap: nowrap;
        }
        .auth-expo {
            font-size: 1rem;
            color: $black;
            width: 100%
        }
        .date-expo {
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: $kasi-gray;
            flex-wrap: nowrap;
            white-space: nowrap;
            span {
                white-space: nowrap
            }
        }
    }
}

///inner expo list
#themHeader {
    margin: auto;
    width: 100%;
    display: flex;
    height: 25vh;
    min-height: 190px;
    max-height: 320px;
    align-items: center;
    background-size: cover;
    background-position: center center !important;
    position: relative;
    h1 {
        line-height: 1;
        font-size: 2rem;
        text-transform: none;
        @media #{$large-up} {
            font-size: 3rem;
        }
        @media #{$xlarge-up} {
            font-size: 4rem;
        }
    }
}

.containerGridexpos {
    position: relative;
    width: 100%;
    margin: 0px auto;
    height: 100%;
    display: grid;
    grid-template-columns: 42px 1fr;
    grid-gap: 0px;
    @media #{$large-up} {
        grid-template-columns: 82px 1fr;
    }
    #themHeader {
        height: 20vh;
        min-height: 160px;
        max-height: 320px;
    }
    &.expodetail {
        grid-template-columns: 42px 42px 1fr;
        @media #{$large-up} {
            grid-template-columns: 82px 82px 1fr;
        }
        .headergrid {
            grid-column: span 3;
        }
        .maingridexpos,
        .content-box {
            grid-column: 3/3;
            a {
                &:hover {
                    color: $red !important;
                }
            }
            &.top3rel {
                position: relative;
                top: -3rem;
            }
        }
        .total {
            grid-column: span 3;
        }
        .sidebar {
            &.black {
                background: $black;
            }
        }
        .recenter-side1 {
            overflow: hidden;
            width: calc(100% - 24px);
            @media #{$large-up} {
                width: calc(100% - 82px);
            }
            &.slidethems {
                margin: auto;
            }
        }
        #themHeader {
            height: 35vh;
            min-height: 280px;
            max-height: 380px;
            h1 {
                text-transform: none;
                font-weight: 600;
            }
            @media #{$large-up} {
                height: 38vh;
                min-height: 300px;
            }
        }
    }
    &.chaptdetail {
        grid-template-columns: 42px 42px 1fr;
        @media #{$large-up} {
            grid-template-columns: 82px 82px 1fr 82px;
        }
        .headergrid {
            grid-column: span 4;
        }
        .maingridexpos,
        .content-box {
            grid-column: 3/3;
            overflow: hidden;
            grid-row: 1 / 100;
        }
        .total {
            grid-column: span 4;
        }
        .sidebar {
            &.black {
                background: $black;
            }
            &.rightbar {
                grid-column: 4/4;
                display: none;
                @media #{$medium-up} {
                    display: block;
                }
            }
        }
        .recenter-side1 {
            width: 100%;
            @media #{$large-up} {
                width: calc(100% - 41px);
            }
            @media #{$xlarge-up} {
                width: calc(100% - 82px);
            }
        }
        #themHeader {
            height: 30vh;
            max-height: 320px;
        }
    }
    .recenter-side1 {
        width: 100%;
        @media #{$medium-up} {
            width: calc(100% - 82px);
        }
    }
    .sidebar {
        grid-row: 1 / 100;
        background-color: $red;
    }
    .headergrid {
        grid-column: span 2;
    }
    .maingridexpos {
        grid-column: 2/2;
    }
    .total {
        grid-column: span 2;
    }
    .credits {
        margin-top: 3.25rem;
        clear: both;
        float: left;
        width: 100%;
        margin-bottom: 3.25rem;
        .credlist {
            font-size: 0.875rem;
            opacity: 0.66;
        }
    }
}

.end-box.black {
    a {
        text-decoration: underline;
        &:hover {
            color: $red !important;
        }
    }
}

//// marquee
.marquee {
    position: relative;
    margin: 0 16px 0rem 0;
    .listaut {
        font-size: 0.875rem;
        @media #{$medium-up} {
            position: absolute;
            white-space: nowrap;
            transform: translateX(0);
            transition: 1s;
            display: flex;
            span {
                display: inline-block;
                float: left;
                margin: 0;
                pointer-events: none;
            }
        }
    }
    &:hover {
        span {}
    }
}

.presexpo {
    margin-top: 4rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid rgba($light-gray, 0.5);
    margin-bottom: 2.4rem;
    article {
        display: flex;
        flex-direction: column;
        @media #{$large-up} {
            flex-direction: row;
        }
    }
    .introFirst {
        font-weight: 600;
        text-align: justify;
    }
    .citeForExpo {
        margin-top: 1rem;
    }
    .citation {
        font-size: 1.125rem;
        font-style: italic;
        margin-bottom: 0.625rem;
        @media #{$xxlarge-up} {
            font-size: 1.125rem;
        }
    }
    .citation_auth {
        font-size: 1rem;
        font-weight: 300;
        color: $light-gray;
    }
    .expo-picture {
        width: 100%;
        float: left;
        margin-right: 2rem;
        @media #{$small-only} {
            width: 100%;
            img {
                width: 100%;
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }
        @media #{$medium-up} {
            width: 280px;
        }
        @media #{$large-up} {
            width: 360px;
        }
    }
    a {
        text-decoration: underline;
    }
}

.expo-picture {
    figure {
        margin: 0;
    }
    width:360px;
}

.expo-intro {
    margin-left: 0rem;
    width: 100%;
    font-size: 1rem;
    @media #{$xxlarge-up} {
        margin-left: 0rem;
        width: 100%;
        font-size: 1rem;
        p {
            font-size: 1rem;
        }
    }
    .introMore {
        font-family: 'Inria Serif';
        font-weight: 400;
        text-align: justify;
        a {
            text-decoration: underline
        }
        @media #{$xxlarge-up} {
            font-size: 1rem;
            p {
                font-size: 1rem;
            }
        }
    }
}

.expoPlan {
    margin-top: 1.5em;
    margin-bottom: 3.5rem;
    h2 {
        text-transform: none;
        font-weight: 500;
        font-size: 2.25rem;
    }
}

.captl_h2 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2.25rem;
}

#recapCred {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.listchapter {
    padding-top: 3rem;
    padding-bottom: 3rem;
    .chapterItem {
        padding: 0.5rem;
        &.active {
            border: 1px $red solid;
        }
    }
    .navsliderby3 a:hover {
        .icon-2x {
            &:before {
                color: $red !important;
            }
        }
    }
    .icon-2x {
        &:before {
            color: $white !important;
        }
    }
}

#paraghMenu {
    margin: 2.5rem 0;
    display: flex;
    flex-wrap: wrap;
    .menuchapItem {
        &:hover {
            .titlepar {
                color: $red !important;
                * {
                    color: $red !important;
                }
            }
        }
    }
    >div {
        width: 50%;
        float: left;
        display: flex;
        align-items: center;
        margin: 0.5rem 0;
        cursor: pointer;
        @media #{$large-up} {
            width: 25%;
        }
        >.titlepar {
            font-size: 0.875rem;
            padding: 0 0.75rem;
        }
        >.imgpar {
            width: 50px;
            height: 40px;
            position: relative;
            flex: 0 0 50px;
            @media #{$large-up} {
                width: 100px;
                height: 72px;
                flex: 0 0 100px;
            }
            img {
                object-fit: cover;
                position: absolute;
                min-width: 100%;
                min-height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

#folder {
    p {
        a {
            color: $red !important;
            span,
            b,
            em,
            i {
                color: $red !important;
            }
        }
    }
}

.introfold,
.descrfold {
    font-family: 'Inria Serif', serif;
    * {
        line-height: 1.875rem;
        margin-bottom: 0;
    }
    a {
        text-decoration: underline;
    }
}

.introfold {
    text-align: justify;
}

.descrfold {
    p {
        text-align: justify;
    }
}

.folder {
    margin-top: 2rem;
    float: left;
    h3 {
        font-size: 1.5rem;
        font-style: italic;
    }
    a {
        color: $red !important;
        span,
        b,
        em,
        i {
            color: $red !important;
        }
    }
    .descrimgfold {
        a {
            color: $black !important;
            span,
            b,
            em,
            i {
                color: $black !important;
            }
        }
        span,
        b,
        em,
        i {
            color: $black !important;
        }
    }
}

.citationFolder {
    ;
    .is-content {
        //border-left: 0.25rem solid #ddd;
        width: 100%;
        background: linear-gradient(90deg, white, transparent);
        padding: 2rem;
        padding-left: 4rem;
        //box-shadow: -21px 0px 20px -17px rgba($black,0.1);
    }
}

.moreblock {
    display: block;
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba($dark-gray2, 0.5);
    .showfolder {
        >span {
            display: flex;
            align-items: center;
            float: right;
            span.more {
                font-size: 2em;
                font-weight: 100;
                padding-left: 0.3em;
            }
        }
        .morebut {
            display: inline-block;
        }
        .lessbut {
            display: none;
        }
        &.opened {
            .morebut {
                display: none;
            }
            .lessbut {
                display: inline-block;
            }
        }
    }
}

.italic {
    font-style: italic;
}

.detailfolder {
    width: 100%;
    overflow: hidden;
    margin-top: 1rem;
    float: left;
    .small-text {
        * {
            font-size: 0.625rem;
            &:before {
                display: none
            }
        }
    }
    .slick-arrow {
        position: absolute;
        bottom: 1rem;
        z-index: 10;
        &:before {
            font-size: 2rem;
        }
    }
    .slick-prev {
        left: -1rem
    }
    .slick-next {
        right: -1rem
    }
    .slick-slide {
        text-align: center;
        color: #419be0;
        background: transparent;
        margin: 1px;
        img {
            width: 100%;
        }
    }
}

.containerPara {
    display: flex;
    flex-direction: column;
    >h3 {
        border-left: 3px solid $red;
        padding-left: 0.75rem;
    }
}

.folder-detail {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &.f_citation {
        margin-bottom: 2rem;
        margin-top: 2rem;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 0;
            width: 2px;
            height: 100%;
            background: $red;
        }
    }
    @media #{$xlarge-up} {
        flex-direction: row;
    }
    &.alignright {
        flex-direction: column;
        @media #{$xlarge-up} {
            flex-direction: row-reverse;
        }
    }
    &.alignfull {
        flex-direction: column;
        >.column {
            width: 100%;
        }
    }
    .tag {
        margin-top: 1rem;
    }
    &.is-with-image {
        display: block;
        .descrfold {
            float: initial;
            text-align: left;
            margin: 0 0.7rem;
        }
        .store-album {
            display: inline-block;
            float: left;
            padding: 0;
            margin: 1.75rem;
            margin-top: 0;
            .slick-slide {
                background: #dfdfdf;
            }
            button {
                &:hover {
                    color: $red
                }
            }
            .descrimgfold {
                padding: 0.5rem 1rem;
                a * {}
            }
        }
        &.alignright {
            .store-album {
                float: right;
            }
        }
    }
}

.slider-img-folder {
    .slick-track {
        display: flex;
        align-content: stretch;
        .slick-slide {
            align-self: stretch;
            height: auto;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            .img-sl-cont {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

.slider-img-folderby-3 {
    width: 100%;
    @media #{$large-up} {
        width: 76vw;
    }
    .item-img-folder {
        padding-right: 0.5rem;
        @media #{$large-up} {
            padding-right: 2.5rem;
        }
        .small-text {
            min-height: 3rem;
        }
    }
}

.store-album {
    .item-img-folder {
        h6 {
            text-transform: none;
            text-align: center;
        }
    }
}

section.block.black {
    .icon-2x:before {
        color: $white;
        font-size: 1.8rem;
        ;
    }
}

.recenter-side-resume {
    padding-top: 2rem;
    padding-bottom: 0.25rem;
    .slider-img-folderby-3 {
        margin-top: 2rem;
    }
    .row.larger {
        max-width: 80rem;
    }
}

.navsliderby3 {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
        padding: 1rem;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }
}

.navbar-thematics-more {
    position: sticky;
    top: 0;
    ul {
        background: transparent;
        display: flex;
        flex-direction: row;
        margin: 0;
        margin-top: 1.5rem;
        border-top: 1px rgba($white, 0.5) solid;
        border-left: 1px rgba($white, 0.5) solid;
        flex-wrap: wrap;
        //margin-bottom: 0.5em;
        li {
            display: flex;
            border-bottom: 1px solid rgba($white, 0.5);
            border-right: 1px solid rgba($white, 0.5);
            width: calc(100% / 3);
        }
        a {
            display: block;
            float: left;
            width: 100%;
            background: $black;
            padding: 1rem 2rem;
            font-family: 'varta';
            text-transform: uppercase;
            text-align: left;
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 700;
            color: $black;
            &:hover {
                background: $red;
            }
        }
    }
    ul.large-8 {
        li {
            width: calc(100% / 2);
        }
    }
    ul.large-4 {
        li {
            width: calc(100%);
        }
    }
}

#chaptersSlide {
    .slick-track {
        display: flex;
    }
    .card-expo {
        figcaption {
            font-size: 0.75rem;
        }
    }
}

.nopoint {
    pointer-events: none;
}

#themHeader {
    .permalinks {
        a.citeurl {
            color: $white;
            font-weight: 700;
            background-color: $black;
            border-radius: 6px;
        }
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

.img-aud {
    margin-bottom: 1rem;
}

//video track list
.tracklist {
    display: block;
    margin: 1rem 0;
    counter-reset: num;
    margin-top: 0;
    margin-left: 0;
    p {
        counter-increment: num;
        display: list-item;
        font-size: 0.75rem;
        margin: 0 0.5rem;
        margin-left: 0;
        list-style: none;
        border-radius: 6px;
        cursor: pointer;
        &:before {
            content: counter(num)') ';
        }
    }
}