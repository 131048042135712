$icon-collections: "\e91e";
$icon-tag: "\e91f";
$icon-arrow-right: "\e915";
$icon-download: "\e916";
$icon-film1: "\e917";
$icon-map1: "\e918";
$icon-message-circle: "\e919";
$icon-videocam: "\e91a";
$icon-collection: "\e90d";
$icon-expositions: "\e90e";
$icon-outils: "\e90f";
$icon-reperes: "\e910";
$icon-calendar: "\e90a";
$icon-museummore: "\e90b";
$icon-plan: "\e90c";
$icon-play: "\e909";
$icon-mail: "\e904";
$icon-cloud_doc: "\e905";
$icon-comment: "\e906";
$icon-doc: "\e907";
$icon-linkdoc: "\e908";
$icon-link: "\e903";
$icon-arro_left: "\e900";
$icon-arro_right: "\e901";
$icon-map: "\e902";
$icon-image: "\e913";
$icon-video: "\e914";
$icon-sound: "\e912";
$icon-search: "\e911";
$icon-music: "\f001";
$icon-film: "\f008";
$icon-volume-up: "\f028";
$icon-video-camera: "\f03d";
$icon-image2: "\f03e";
$icon-photo: "\f03e";
$icon-picture-o: "\f03e";
$icon-pencil: "\f040";
$icon-question-circle: "\f059";
$icon-eye: "\f06e";
$icon-eye-slash: "\f070";
$icon-twitter: "\f099";
$icon-facebook: "\f09a";
$icon-filter: "\f0b0";
$icon-file-text-o: "\f0f6";
$icon-order: "\f150";
$icon-youtube-square: "\f166";
$icon-youtube: "\f167";
$icon-newspaper-o: "\f1ea";
$icon-long_arrow_left: "\e91c";
$icon-long_arrow_right: "\e91b";
$icon-close: "\e91d";

.icon-collections {
  &:before {
    content: $icon-collections;
  }
}
.icon-tag {
  &:before {
    content: $icon-tag;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-long_arrow_left {
  &:before {
    content: $icon-long_arrow_left;
  }
}
.icon-long_arrow_right {
   &:before {
     font-size: 1.8rem;
     content: $icon-long_arrow_right;
   }
 }
.icon-arrow-right {
  &:before {
    content: $icon-arrow_right;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-film1 {
  &:before {
    content: $icon-film1;
  }
}
.icon-map1 {
  &:before {
    content: $icon-map1;
  }
}
.icon-message-circle {
  &:before {
    content: $icon-message-circle;
  }
}
.icon-videocam {
  &:before {
    content: $icon-videocam;
  }
}
.icon-collection {
  &:before {
    content: $icon-collection;
  }
}
.icon-expositions {
  &:before {
    content: $icon-expositions;
  }
}
.icon-outils {
  &:before {
    content: $icon-outils;
  }
}
.icon-reperes {
  &:before {
    content: $icon-reperes;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-museummore {
  &:before {
    content: $icon-museummore;
  }
}
.icon-plan {
  &:before {
    content: $icon-plan;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
  }
}
.icon-cloud_doc {
  &:before {
    content: $icon-cloud_doc;
  }
}
.icon-comment {
  &:before {
    content: $icon-comment;
  }
}
.icon-doc {
  &:before {
    content: $icon-doc;
  }
}
.icon-linkdoc {
  &:before {
    content: $icon-linkdoc;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
  }
}
.icon-arro_left {
  &:before {
    content: $icon-arro_left;
  }
}
.icon-arro_right {
  &:before {
    content: $icon-arro_right;
  }
}
.icon-map {
  &:before {
    content: $icon-map;
  }
}
.icon-image {
  &:before {
    content: $icon-image;
  }
}
.icon-video {
  &:before {
    content: $icon-video;
  }
}
.icon-sound {
  &:before {
    content: $icon-sound;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-music {
  &:before {
    content: $icon-music;
  }
}
.icon-film {
  &:before {
    content: $icon-film;
  }
}
.icon-volume-up {
  &:before {
    content: $icon-volume-up;
  }
}
.icon-video-camera {
  &:before {
    content: $icon-video-camera;
  }
}
.icon-image2 {
  &:before {
    content: $icon-image2;
  }
}
.icon-photo {
  &:before {
    content: $icon-photo;
  }
}
.icon-picture-o {
  &:before {
    content: $icon-picture-o;
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil;
  }
}
.icon-question-circle {
  &:before {
    content: $icon-question-circle;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
  }
}
.icon-eye-slash {
  &:before {
    content: $icon-eye-slash;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-file-text-o {
  &:before {
    content: $icon-file-text-o;
  }
}
.icon-order {
  &:before {
    content: $icon-order;
  }
}
.icon-youtube-square {
  &:before {
    content: $icon-youtube-square;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-newspaper-o {
  &:before {
    content: $icon-newspaper-o;
  }
}


.fa-question-circle{
  @extend  .icon-question-circle;
}

.fa-chevron-right {
  @extend  .icon-arro_right;
}
.fa-chevron-left {
  @extend  .icon-arro_left;
}
.fa-search {
  @extend  .icon-search ;
}

