/*
 * Common addons
 */
*:focus {
  outline: 0;
}


// Raccourci de positionnement relatif
.relative-position{
    position: relative;
}

// Image en bloc centre
img.centered{
    display: block;
    margin-left: auto;
    margin-right: auto;
  
}
// Etire proportionellement l'image a la largeur de son conteneur
img.expanded{
    width: 100%;
    height: auto;
}

// Fluid row, take the full width but keep normal row and columns behaviors
.row.row-fluid{
    max-width: 100%;
    // Restore the initial behavior restrained to the grid
    .row{
        margin: auto;
        @include grid-row;
        // Preserve nested fluid behavior
        &.row-fluid{
            max-width: 100%;
        }
    }
}


/* vertspace for a top and bottom margin*/

//larger row
.vertSpace {
  padding-bottom:1rem;
  padding-top:1rem;
}


//larger row X2
.vertSpaceX2 {
  padding-bottom:2rem;
  padding-top:2rem;
}


.marginTopLarge {
  @include breakpoint(xlarge up) {
    margin-top: 1rem;
  }
}

.emphatism {
text-transform: uppercase;
  font-style: italic;
}
.capitunder {
text-transform: uppercase;
  text-decoration:underline;
}
//line under
.lineUnder {
  position:relative;
  &:after {
    content:'';
    background:#000;
    width:3rem;
    height:3px;
    position: absolute;
    bottom:-0.5rem;
    left:50%;

    @include translate(-50%,0%);

  }
}

.unpadded {
padding:0;
}
.unmargin {
  margin:0;
}

// Remove column padding for simple inner
//.row .columns > .row-inner{
//    margin-left: -($column-gutter/2);
//    margin-right: -($column-gutter/2);
//}

// Remove padding on columns
.columns{
    &.unpadded{
        padding: 0;
    }
    @include breakpoint(small only) {
        &.small-unpadded{
            padding: 0;
        }
    }
    @include breakpoint(medium only)  {
        &.medium-unpadded{
            padding: 0;
        }
    }
    @include breakpoint(large only) {
        &.large-unpadded{
            padding: 0;
        }
    }
}
////modal extend
body.modal-open {
  overflow: hidden;
  min-height: 100vh;
}
//.reveal-modal {
// background-color: transparent;
//  height:100vh;
//  width:100%;
//  box-shadow: none;
//  position:fixed;
//  @include reveal-modal-style(
//    // Sets background color of reveal modal. Default: $reveal-modal-bg or #fff
//    $bg:transparent,
//      // Set reveal border style. Default: $reveal-border-style or solid
//    $border:false,
//      // Color of border. Default: $reveal-border-color.
//    $border-width:0px,
//      // Choose whether or not to include the default box-shadow. Default: true, Options: false
//    $box-shadow:true,
//      // Default: $reveal-position-top or 50px
//    $top-offset:0px
//  );
//
//}

// relative block
.relative {
  position: relative;
}
// Remove all margins
.unmarged{
    margin: 0;
}

/*
 * Supplementary divider classes
 */
// Apply margin bottom on rows
.row.divider{
    margin-bottom: rem-calc(10px);
    @include breakpoint(medium up) {
        margin-bottom: rem-calc(30px);
    }
}
// By default, columns dont needs of a divider in medium display and up,
// because they should probably floating
.columns.divider{
    margin-bottom: rem-calc(5px);
    @include breakpoint(medium up) {
        margin-bottom: 0;
    }
}

// Sometime we need to separate an img from an another adjacent element
img.divider{
    margin-bottom: $paragraph-margin-bottom;
}


/*
 * Visually transform a dropdown button in.to a select list with vertical
 * scrollbar. This is useful for long list items that can impact on page size
 */
.f-dropdown.overflow{
    overflow: auto;
    height: 200px;
    // Various sizes
    &.overflow-100{ height: 100px; }
    &.overflow-150{ height: 150px; }
    &.overflow-200{ height: 200px; }
    &.overflow-250{ height: 250px; }
    &.overflow-300{ height: 300px; }
}

.smallrounded {
  border-radius: 6px;
  overflow:hidden;
  z-index: 0;
}

.centerabs {
  top:50%;
  left:50%;
  position: absolute;
  @include translate(-50%, -50%);
}

.bottomRightabs {
  bottom:0%;
  right:0%;
  position: absolute;

}

.bottomabs {
  bottom:0%;
  left:0%;
  position: absolute;

}

.margin {
  margin:1.5rem;
}

.round {
  border-radius: 500px;
}




.button.blackbutton {
  background: $black;
  &hover {
    background: $red;
  }
}

.is-active {
  background: $medium-gray;
}

.input-group {
  .start,.end {
    width:48%;
    float: left;

  }
  .input-group-addon {
    width: auto;
    text-align: center;
    float:left;
    white-space: nowrap;
  }
}
//////////////////////connection box
.box {
  position: relative;
  top: 0;
  opacity: 1;
  float: left;
  padding: 60px 50px 40px 50px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  z-index: 5;
  &.back {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    top: -20px;
    opacity: .8;
    z-index: -1;
  }
  &:before {
    content: "";
    width: 100%;
    height: 30px;
    border-radius: 10px;
    position: absolute;
    top: -10px;
    background: rgba(255, 255, 255, 0.6);
    left: 0;
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    z-index: -1;
  }
}

.overbox .title {
  color: #fff;
  &:before {
    background: #fff;
  }
}

.materialContainer {
  .title {
    width: 100%;
    float: left;
    line-height: 46px;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 2px;
    color: #ED2553;
    position: relative;
    &:before {
      content: "";
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -50px;
      background: #ED2553;
    }
  }
  .input {
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    label, input, .spin {
      transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
      -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
      -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  .button {
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    button .button.login button i.fa {
      transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
      -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
      -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  .material-button .shape {
    &:before, &:after {
      transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
      -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
      -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  .button.login button {
    transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
    -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}

.material-button, .alt-2, .material-button .shape, .alt-2 .shape, .box {
  transition: 400ms cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition: 400ms cubic-bezier(0.4, 0, 0.2, 1);
  -ms-transition: 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

.materialContainer {
  input:-webkit-autofill {
    background-color: $white !important;
  }
  .input {
    width: 100%;
    float: left;
    label, input, .spin {
      width: 100%;
      float: left;
      &:-webkit-autofill {
        background-color:#000;
      }
    }
  }
  .button {
    width: 100%;
    float: left;
    button {
      width: 100%;
      float: left;
    }
  }
  .input, .button {
    margin-top: 30px;
    height: auto;
  }
  .input {
    position: relative;
    input {
      position: relative;
    }
  }
  .button {
    position: relative;
    button {
      position: relative;
    }
  }
  .input {
    input {
      height: 60px;
      top: 10px;
      border: none;
      background: transparent;
      font-size: 24px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 300;
    }
    input[type="text"] ,input[type="password"] {

       box-shadow:0 0 0 rgba(0,0,0,0);
      &:focus {
        border:0;
         box-shadow:0 0 0 rgba(0,0,0,0);

      }}
    label {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 300;
    }
  }
  .button button {
    padding:0;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 300;
  }
  .input {
    &:before, .spin {
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.1);
      z-index: 3;
    }
    .spin {
      background: #ED2553;
      z-index: 4;
      width: 0;
    }
  }
}

.overbox .input {
  .spin {
    background: rgba(255, 255, 255, 1);
  }
  &:before {
    background: rgba(255, 255, 255, 0.5);
  }
}

.materialContainer {

  .input label {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 2;
    cursor: pointer;
    line-height: 60px;
    background-color: #FFF;
  }

  .button {
    &.login {
      width: 60%;
      left: 20%;
      padding:0;
      button {
        width: 100%;
        line-height: 64px;
        left: 0%;
        background-color: transparent;
        border: 3px solid rgba(0, 0, 0, 0.1);
        font-weight: 900;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.2);
      }
    }
    button {
      width: 100%;
      line-height: 64px;
      left: 0%;
      background-color: transparent;
      border: 3px solid rgba(0, 0, 0, 0.1);
      font-weight: 900;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.2);
    }
    &.login {
      margin-top: 30px;
    }
    margin-top: 20px;
    button {
      background-color: #fff;
      color: #ED2553;
      border: none;
    }
    &.login button {
      &.active {
        border: 3px solid transparent;
        color: #fff !important;
        span {
          opacity: 0;
          transform: scale(0);
          -webkit-transform: scale(0);
          -ms-transform: scale(0);
        }
        i.fa {
          opacity: 1;
          transform: scale(1) rotate(-0deg);
          -webkit-transform: scale(1) rotate(-0deg);
          -ms-transform: scale(1) rotate(-0deg);
        }
      }
      i.fa {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        line-height: 60px;
        transform: scale(0) rotate(-45deg);
        -webkit-transform: scale(0) rotate(-45deg);
        -ms-transform: scale(0) rotate(-45deg);
      }
      &:hover {
        color: #ED2553;
        border-color: #ED2553;
      }
    }
    margin: 40px 0;
    overflow: hidden;
    z-index: 2;
    button {
      cursor: pointer;
      position: relative;
      z-index: 2;
    }
  }
}

.pass-forgot {
  width: 100%;
  float: left;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

.click-efect {
  position: absolute;
  top: 0;
  left: 0;
  background: #ED2553;
  border-radius: 50%;
}

.overbox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: inherit;
  border-radius: 10px;
  padding: 60px 50px 40px 50px;
  .title, .button, .input {
    z-index: 111;
    position: relative;
    color: #fff !important;
    display: none;
  }
  .title {
    width: 80%;
  }
  .input {
    margin-top: 20px;
    border:0;
     box-shadow:0 0 0 rgba(0,0,0,0);
    &:focus {
      border:0;
       box-shadow:0 0 0 rgba(0,0,0,0);

    }
    label {
      background-color:$red;
    }
    input, label {
      color: $white;
    }
  }
  .material-button, .alt-2 {
    display: block;
    .shape {
      display: block;
    }
  }
}



.material-button,.alt-2 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ED2553;
  position: absolute;
  top: 40px;
  right: -40px;
  cursor: pointer;
  z-index: 100;
  transform: translate(0%, 0%);
  -webkit-transform: translate(0%, 0%);
  -ms-transform: translate(0%, 0%);
  .shape {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
    &:before,&:after {
      content: "";
      background: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 25px;
      height: 4px;
      transform: translate(-50%, -50%) rotate(360deg);
      -webkit-transform: translate(-50%, -50%) rotate(360deg);
      -ms-transform: translate(-50%, -50%) rotate(360deg);
    }
    &:before {
      width: 25px;
      height: 4px;

    }
    &:after {
      width: 4px;
      height: 25px;

    }
}
  &.active {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%) rotate(0deg);
    -webkit-transform: translate(50%, -50%) rotate(0deg);
    -ms-transform: translate(50%, -50%) rotate(0deg);

  }

}



.materialContainer {
  width: 100%;
  max-width: 460px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.reveal-modal .close-reveal-modal {
  color: $red;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: 900;
  position: absolute;
  top: -1.625rem;
  right: -1.375rem;
  background: $white;
  display: block;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border-radius: 500px;
  line-height: 2.6rem;
}
.close-reveal-modal {display:none}

.clear {
  clear:both;
}

.unpadded-small {

  @include breakpoint(large down) {
    padding:0;
  }
}
[data-link] {
  &:hover {
  cursor:pointer;
  }
}

.circle-icon {
  border: 1px solid rgba(255,255,255,0.5);
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
  padding: 4px;
  display: block;
  margin: 4px;
  background: rgba(0,0,0,0.82);
  width:4rem;
  height:4rem;
  &:before {
    line-height:3.4rem;
  }
}

.circle-social {
  border:2px solid #000;
  -webkit-border-radius:50%;
  -moz-border-radius:50%;
  border-radius:50%;
  padding: 4px;
  display: block;
  margin: 4px;
  color: #000;
}

.button.full {
  @include calc('width','100% - 3px');
  margin:1px;
  margin-bottom:2px;
}

#langChooser {
  position: relative;
  a {padding-left:2rem;padding-right:1rem;&:before{top:-2px;left:2px;}}
  .showinUP {
    width:100%;
    a {width:100%;}
    opacity: 0;
    z-index: -10;
    overflow:hidden;
    position:absolute;
    bottom:100%;

    border-bottom: 0;
    border-left: 0;
    .range-slider.vertical-range {
      position: relative;
      left:50%;
    }
    .range-slider-handle,.range-slider-handle:hover {
      height:1.9rem;
      width:1.9rem;
      border-radius: 1.9rem;

    }
    .range-slider-active-segment,.range-slider.vertical-range {
      border-radius :2rem;
    }
  }
  &:hover {
    .showinUP {
      opacity: 1;
      z-index: 1;


    }
  }
}



.full-width {
  max-width: 100%;
}


//onglet sim
.onglets {
  @include tabs-container;
  border:none;
  background:darken($dark-gray,20%);
  .tab{
    padding:0.2rem 1rem;
    background:($dark-gray,20%);
    @include tabs-title;
    color: $white;
    &.selectionned {
      background:$white;
      color: $black;
      &:hover {
        background:$white;
        cursor: pointer;
      }
    }
    &:hover {
      background: $black;
      cursor: pointer;
    }
  }
}

//z-index nav
.is-dropdown-submenu {
  z-index: 100;}




/* Autocomplete
----------------------------------*/
.ui-autocomplete { position: absolute; cursor: default;z-index: 20 }
.ui-autocomplete-loading { background: white url('images/ui-anim_basic_16x16.gif') right center no-repeat; }
.ui-helper-hidden-accessible {
  display:none;
}

/* workarounds */
* html .ui-autocomplete { width:1px; } /* without this, the menu expands to 100% in IE6 */

/* Menu
----------------------------------*/
.ui-menu {
  list-style:none;
  padding: 10px;
  margin: 0;
  display:block;
  width:auto;
  background:#FDFEFF;
  border:1px solid $black;
}
.ui-menu .ui-menu {
  margin-top: -3px;
}
.ui-menu .ui-menu-item {
  margin:0;
  padding: 0;
  width: auto;
  border-bottom:1px solid $light-gray;
  &:hover {color:$red;}
}
.ui-menu .ui-menu-item a {
  text-decoration:none;
  display:block;
  padding:.2em .4em;
  line-height:1.5;
  zoom:1;
}
.ui-menu .ui-menu-item a.ui-state-hover,
.ui-menu .ui-menu-item a.ui-state-active {
  margin: -1px;
}

/****** results *******/
.results.media-list {
  float: left;
  width: 100%;
  margin-top:1rem;padding-top:1rem;
  .media {
    padding:0.64rem;
    border-top:1px dashed $medium-gray;
    display: flex;
    .figure {
      margin-right:0.64rem;
      img {
        min-width: 240px;
      }
    }
  }
}



///***global-aside***.//

#global-aside {
  position: relative;
}

div.table-container {
  width:100%;
}



///***lib *****/

.library {
  .header {
    margin: 15px 0;
  }

  .thumbnails img {
    margin: 10px 0;
  }
  .menu-thumbnails {
    text-align: left;
  }
}

.reader {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .btn-group {
    margin-bottom: 5px;
    &.moreForimg {
      .button {
        font-size:1.8rem;
        padding: 0.4rem;
        &.zoom{margin-right:0 ;}
      }
    }
  }
  .row {
    margin: 0;
    .prev, .next, .spinner-container, .img-container {
      $height: 800px;
      height:$height;
      line-height: $height;
      vertical-align: middle;
    }
    .main-container {
      padding: 0;
      position:relative;
      background: $black;
    }
    .spinner-container {
      position: absolute;
      width: 100%;
      text-shadow: 0 0 40px white;
      display:none;
    }
    .img-container {
      &.zoomed {
        overflow: scroll;
        img {
          max-width: none;
          max-height: none;
          cursor: zoom-out;
          &.dragged {
            cursor: move;
          }
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
        cursor: zoom-in;
      }
    }
  }
  .page-form {
    width: 100px;
    margin: 8px auto 2rem / 2;
    input[name='page'] {
      text-align: right;
    }
  }
}

#offensive-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30;
  color: $white;
  background-color: $black;
  main {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    h1, p {
      text-align: center;
      a {
        padding: 2rem;
      }
    }
  }
}

#numberpage {
  width:auto;
  #page_number {
    width:90px;
    float:left;
  }
  .input-group-addon {
    display:inline-block;
    float:left;
    line-height:2.4375rem;
    padding-left: 0.6rem;
  }

}



////
.isMobile {
  .homban_vid {
    display: none;

  }
  .homban_img {
    display:block
  }
}

.homban_img {
  min-width:100% ;
  height:50vh;
  position: relative;
  display: none;
  img {
    position: absolute;
  }
}

///dl-horizontal
dl.dl-horizontal {
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  float: left;
 dt {
    margin-bottom: 0.3rem;
    float: left;
   clear:left;
   color:#666;
   width:12rem;
   &:after {
     content:' : ';
   };
  }
  dd {
    margin-bottom: 0.3rem;
    float: left;
    margin-left:0.4rem;
    width: calc(100% - 15rem);
  }
}

dl.dl-horizontal-tight {
  margin-bottom: 1rem;
  display: block;
  width: 100%;
  float: left;
 dt {
    margin-bottom: 0.3rem;
    float: left;
   clear:left;
   color:#666;
   width:8rem;
   &:after {
     content:' : ';
   };
  }
  dd {
    margin-bottom: 0.3rem;
    float: left;
    margin-left:0.4rem;
    width: calc(100% - 15rem);
  }
}
