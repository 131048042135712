.rst {
  h1 {
    @extend h2;
  }
  h2 {

    @extend h3;
  }
  h3 {

    @extend h4;
  }
  h4 {

    @extend h5;
  }
  a.fn-backref {
    color: $white;
  }
  .footnote {
    td {
      width:100%;

      padding:0.2rem;
    &.label {
      width: auto;
      padding:0.6rem;
    }
    }

  }
}