#cookie-law-container {
  background: #5b5b5b;
  z-index: 3000;
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin-left: 20px;
  color: #ffffff;
  padding: 13px;
  max-width: 370px;
  .button {
    width: 100%;
    text-transform: uppercase;
  }
  p {
    font-size: .9rem;
    line-height: 1.2;
  }
}
