    html.isMobile {




            @media
            only screen and (-webkit-min-device-pixel-ratio: 2)      and (max-width: 1536px),
            only screen and (   min--moz-device-pixel-ratio: 2)      and (max-width: 1536px),
            only screen and (     -o-min-device-pixel-ratio: 2/1)    and (max-width: 1536px),
            only screen and (        min-device-pixel-ratio: 2)      and (max-width: 1536px),
            only screen and (                min-resolution: 192dpi) and (max-width: 1536px),
            only screen and (                min-resolution: 2dppx)  and (max-width: 1536px) {
            font-size:160%;

        }
        /* Small screen, retina, stuff to override above media query */



    @include breakpoint(retina) {
        //font-size:400%;


        .square{
            height: 60px;
            width: 60px;
        }
        .footer-links {
            display: inline-block;
            width: 100%;
        }
    }
}


/*
 * Typography and alike
 */

// Dont use the same font weight for b/strong
b{
    font-weight: 600;
}
strong{
    font-weight: 700;
}

// text-*** name formats
.text-upper{
    text-transform: uppercase !important;
}
.text-important{
    text-decoration: underline !important;
}

.text-thin{
    font-weight: 300 !important;
}
.text-bold{
    font-weight: 400 !important;
}
.text-strong{
    font-weight: 700 !important;
}
.text-strongest{
    font-weight: 900 !important;
}

// Text colors
.text-red{
    color: $red !important;
}
.text-black{
    color: $black !important;
}
.text-white{
    color: $white !important;
}

// Various <small> modifier
small{
    &.small{
        font-size: 40%;
    }
    &.medium{
        font-size: 60%; //equivalent to default <small> behavior
    }
    &.big{
        font-size: 80%;
    }
}
h1 {
  text-transform: uppercase;
    font-weight: 600;
    line-height: 0.8;
}
h1, h2, h3, h4, h5, h6{
    &.text-thin{
        font-weight: 100 !important;
    }
    &.text-normal{
        font-weight: 400 !important;
    }
    &.text-strong{
        font-weight: 600 !important;
    }
}

.title-1{
    @extend h1;
}
.title-2{
    @extend h2;
}
.title-3{
    @extend h3;
}
.title-4{
    @extend h4;
}
.title-5{
    @extend h5;
}
span {
    &.title-1,&.title-2,&.title-3 ,&.title-4 ,&.title-5{

    }
}
// ???
.h3 {
    @extend h3;
    display: block;
    padding-bottom: 2rem;
    padding-top: 2rem;
}
h6 {
    text-transform:uppercase;
    font-weight:600;
}

// Bigger text
.big:not(small) {
    font-size: 1.4rem;
    font-weight: 600;
}

/*blockquote {
    border-left: 0;
    text-align: left;
    position: relative;
    svg {
        #black {
            fill: #FFFFFF;
            background-color: #FFF;
        }
    }
}*/

hr {
    border-width: 0px;
    border-style: solid;
    border-top: 0;
    border-bottom:1px solid rgba($black,0.5);;

}
footer {
    hr {border-color:#FFF}
    .btn-group {
        @include breakpoint(medium down) { display: inline-block}
    }
}
.hline {
    @include hline;
}

// backgrounds
.background-black {
    background-color: black;
}
.background-white {
    background-color: $white;
}
.rounded {
    border-radius:50%;
}
.square {
    display: inline-block;
    height: 32px;
    width:32px;
    position: relative;
    .icon {
        position: absolute;
        top:50%;
        left:50%;
        @include translate(-50%,-50%)
    }
}
.menu.social {
    li {
        padding-right:0.5rem;
        a {
            color:$white;
        }
    }
}
// Underlined element
.underline{
    // Base
    &:after{
        content:"";
        display: block;
        width: 50%;
        height: rem-calc(3px);
        margin: 1rem 0;
        border-bottom: rem-calc(3px) solid $black;
    }

    // On centered text
    &.text-center{
        &:after{
            margin-left: auto;
            margin-right: auto;
        }
    }

    // Border weight
    &.thin{
        &:after{
            height: rem-calc(1px);
            border-bottom: rem-calc(1px) solid $black;
        }
    }
    &.bold{
        &:after{
            height: rem-calc(5px);
            border-bottom: rem-calc(5px) solid $black;
        }
    }

    // Border width size
    &.tiny{
        &:after{
            width: 5%;
        }
    }
    &.short{
        &:after{
            width: 15%;
        }
    }
    &.normal{
        &:after{
            width: 40%;
        }
    }
    &.large{
        &:after{
            width: 70%;
        }
    }
    &.full{
        &:after{
            width: 100%;
        }
    }

    // Border distance from text
    &.far{
        &:after{
            margin: 2rem 0;
        }
        &.text-center{
            &:after{
                margin: 2rem auto;
            }
        }
    }
    &.near{
        &:after{
            margin: 0.5rem 0;
        }
        &.text-center{
            &:after{
                margin: 0.5rem auto;
            }
        }
    }
    &.sticked{
        &:after{
            margin: 0 0;
        }
        &.text-center{
            &:after{
                margin: 0 auto;
            }
        }
    }
}

/*
 * Various minor rules
 */

[data-link] {
    &:hover {
        cursor: pointer;
        color: $red;
    }
}

.total {
    width: 100%;
}


.gutter ,*.gutter{
    margin:1rem;
}
.full.gutter {
    @include calc('width','100% - 2rem')
}
