

// Available default percentage sizes (real used sizes should be in settings)
//
// Sizes like '18' is when you want a '20%' container but with '1%' horizontal
// marging (aka: 1% + 18% + 1% = 20%)
$flex-grid-sizes: 23 25 31.3333 33.3333 48 50 73 75 98 100 !default;
/*
 * Create flex cell width rules
 */
@mixin -emencia-flex-cell-width($width) {
  //display: flex;
   flex:1 0 $width;
  max-width: $width;
}

/*
 * Mixin to create cells widths with flexbox
 *
 * Will produce responsive class like:
 *
 *   .small-50{...}
 *   .medium-50{...}
 *   etc..
 *
 * Depending on mixin declarations.
 *
 * Usable in conjunction with ".cell".
 */
@mixin -emencia-flex-grid-classes($sizes, $viewport: small) {
  @each $size in $sizes {
    $width: percentage($size / 100);
    $name: floor($size);
    // Will name it like "&.small-50"
    & > .#{$viewport}-#{$name} {
      @include -emencia-flex-cell-width($width);
    }
  }
}

/*
 * Mixin to create flexbox display classes
 *
 * Will produce global and responsive classes like:
 *
 *   .flex{...}
 *   .flex-small{...}
 *   .flex-medium{...}
 *   etc..
 *
 */
@mixin emencia-flex-display($viewport: null) {
  $prefix: if($viewport, "-#{$viewport}", "");
  .flex#{$prefix} {
    display:flex;
  }
}

/*
 * Mixin to create "simple" cells widths
 * Mixin to simulate inline list, used as a fallback
 *
 * Reproduce the same name pattern as in mixin "-emencia-flex-grid-classes"
 */
@mixin -emencia-simulate-grid-columns($sizes, $viewport: small) {
  @each $size in $sizes {
    $width: $size / 100;
    $name: floor($size);
    // Will name it like "&.small-50"
    & > .#{$viewport}-#{$name} {
      width: percentage($width);
    }
  }
}

/*
 * Inline list with flexbox
 */
// For browser with flexbox capability (detected from modernizr)
html{
  @include emencia-flex-display;
  @media #{$small-up} {
    @include emencia-flex-display($viewport: small);
  }
  @media #{$medium-up} {
    @include emencia-flex-display($viewport: medium);
  }
  @media #{$large-up} {
    @include emencia-flex-display($viewport: large);
  }
  @media #{$xlarge-up} {
    @include emencia-flex-display($viewport: xlarge);
  }
  @media #{$xxlarge-up} {
    @include emencia-flex-display($viewport: xxlarge);
  }
  .flex-grid {
    display:flex;
    //@include flex-wrap(wrap);
    // Enforce no horizontal margins to avoid bad cell widths
    margin-left: 0;
    margin-right: 0;

    & > .cell {
      // Default is to have the same width and height for all items using
      // the largest value from all items (default flex display behavior)
      flex:1 0 auto;
      max-width: auto;
      margin: 0;
      list-style-type: none;

      // Last element paragraph dont have bottom margin
      & > p:last-child {
        margin: 0;
      }

      /*
       * Some cell contents alignments
       */
      // All container elements are vertically stacked on center
      &.flex-vertical-start-stack {
        display:flex;
        flex-direction:column;
        justify-content:flex-start;
      }
      // All container elements are vertically stacked on center
      &.flex-vertical-end-stack {
        display:flex;
        flex-direction:column;
        justify-content:flex-en;
      }
      // All container elements are vertically stacked on center
      &.flex-vertical-center-stack {
        display:flex;
        flex-direction:column;
        justify-content:center;
      }
      // All container elements are stacked with space around
      &.flex-space-around-stack {
        display:flex;
        flex-direction:column;
        justify-content:space-around;
      }
      // All container elements are stacked with space between
      &.flex-space-between-stack {
        display:flex;
        flex-direction:column;
        justify-content:space-between;
      }
    }

    /*
     * Responsive cell sizes
     */
    @media #{$small-up} {
      @include -emencia-flex-grid-classes($flex-grid-sizes, $viewport: small);
    }
    @media #{$medium-up} {
      @include -emencia-flex-grid-classes($flex-grid-sizes, $viewport: medium);
    }
    @media #{$large-up} {
      @include -emencia-flex-grid-classes($flex-grid-sizes, $viewport: large);
    }
    @media #{$xlarge-up} {
      @include -emencia-flex-grid-classes($flex-grid-sizes, $viewport: xlarge);
    }
    @media #{$xxlarge-up} {
      @include -emencia-flex-grid-classes($flex-grid-sizes, $viewport: xxlarge);
    }
//TOTOTOTO
    /*
     * Responsive padding in all contained cells
     */
    &.padded .cell {
      padding: 0 1%;
    }
    @media #{$small-up} {
      &.small-padded .cell {
        padding: 0 1%;
      }
    }
    @media #{$medium-up} {
      &.medium-padded .cell {
        padding: 0 1%;
      }
    }
    @media #{$large-up} {
      &.large-padded .cell {
        padding: 0 1%;
      }
    }
    @media #{$xlarge-up} {
      &.xlarge-padded .cell {
        padding: 0 1%;
      }
    }
    @media #{$xxlarge-up} {
      &.xxlarge-padded .cell {
        padding: 0 1%;
      }
    }

    /*
     * Responsive margin on all contained cells
     */
    &.marged .cell {
      margin: 0 1%;
    }
    @media #{$small-up} {
      &.small-marged .cell {
        margin: 0 1%;
      }
    }
    @media #{$medium-up} {
      &.medium-marged .cell {
        margin: 0 1%;
      }
    }
    @media #{$large-up} {
      &.large-marged .cell {
        margin: 0 1%;
      }
    }
    @media #{$xlarge-up} {
      &.xlarge-marged .cell {
        margin: 0 1%;
      }
    }
    @media #{$xxlarge-up} {
      &.xxlarge-marged .cell {
        margin: 0 1%;
      }
    }
  }
}

//// Fallback for browsers that dont have flexbox capability, we inherit from Foundation grid row
//// This may soon be deprecated around 2016
//html.no-flexbox {
//  .flex-grid{
//    list-style-type: none;
//    @extend .row;
//    & > .cell {
//      @extend .columns;
//    }
//    @media #{$small-up} {
//      @include -emencia-simulate-grid-columns($flex-grid-sizes, $viewport: small);
//    }
//    @media #{$medium-up} {
//      @include -emencia-simulate-grid-columns($flex-grid-sizes, $viewport: medium);
//    }
//    @media #{$large-up} {
//      @include -emencia-simulate-grid-columns($flex-grid-sizes, $viewport: large);
//    }
//    @media #{$xlarge-up} {
//      @include -emencia-simulate-grid-columns($flex-grid-sizes, $viewport: xlarge);
//    }
//    @media #{$xxlarge-up} {
//      @include -emencia-simulate-grid-columns($flex-grid-sizes, $viewport: xxlarge);
//    }
//  }
//}
