// Strip unit from a number value
// Usage :
//    strip-units(16px);
// Will return "16" (as a number)
@function strip-units($number) {
    @return $number / ($number * 0 + 1);
}

/*
 * Add some rules to multiply a size (using EM), default is
 * for 'font-size' property
 *
 * Using:
 *
 *     .foo{
 *         @include font-multiplier(2 4 6);
 *     }
 *
 * Will produce something like:
 *
 *    .foo.2x{ font-size: 2em }
 *    .foo.4x{ font-size: 4em }
 *    .foo.4x{ font-size: 6em }
 *
 * Using all optional arguments:
 *
 *     .foo{
 *         @include font-multiplier(2 4 6, $selector-prefix: "icon", $selector-pseudo: "before", $property: "padding-left");
 *     }
 *
 * Will produce something like:
 *
 *    .foo.icon-2x::before{ padding-left: 2em }
 *    .foo.icon-4x::before{ padding-left: 4em }
 *    .foo.icon-4x::before{ padding-left: 6em }
 *
 */
@mixin font-multiplier($sizes, $selector-prefix: null, $selector-pseudo: null, $property: "font-size") {
    $prefix: if($selector-prefix, "#{$selector-prefix}-", "");
    $pseudo: if($selector-pseudo, "::#{$selector-pseudo}", "");
    @each $size in $sizes {
        &.#{$prefix}#{$size}x#{$pseudo} {
            #{$property}: #{$size}rem;
        }
    }
}

// Mixin to make positionning
@mixin positionning($name: absolute, $top: null, $right: null, $bottom: null, $left: null) {
    position: $name;
    @if $top {
        top: $top;
    }
    @if $right {
        right: $right;
    }
    @if $bottom {
        bottom: $bottom;
    }
    @if $left {
        left: $left;
    }
}


/*
 * Mixin to add font and background colors into a container and enforce these
 * colors on its elements
 */
@mixin emencia-container-colors($font-color, $background-color, $h1-color: null, $h2-color: null, $h3-color: null, $h4-color: null, $h5-color: null, $link-color: null, $link-hover-color: null) {
    color: $font-color;
    background-color: $background-color;
    // optionnal custom titles font color, fallback to common font color
    $title-level-1-color: if($h1-color, $h1-color, $font-color);
    $title-level-2-color: if($h2-color, $h2-color, $font-color);
    $title-level-3-color: if($h3-color, $h3-color, $font-color);
    $title-level-4-color: if($h4-color, $h4-color, $font-color);
    $title-level-5-color: if($h5-color, $h5-color, $font-color);
    $common-link-color: if($link-color, $link-color, inherit);
    $common-link-hover-color: if($link-hover-color, $link-hover-color, inherit);

    h1, .title-1{
        color: $title-level-1-color;
    }
    h2, .title-2{
        color: $title-level-2-color;
    }
    h3, .title-3{
        color: $title-level-3-color;
    }
    h4, .title-4{
        color: $title-level-4-color;
    }
    h5, .title-5{
        color: $title-level-5-color;
    }
    h6, .title-6{
        color: inherit;
    }

    a:not(.button){
        color: $common-link-color;
        &:hover{
            color: $common-link-hover-color;
        }
    }
}


/*
 * Vendor prefix for last break-inside syntax (not supported in Compass 0.x)
 */
@mixin break-inside($keyword: auto) {
    -webkit-column-break-inside: $keyword;
            page-break-inside: $keyword;
                break-inside: $keyword;
}

// Base stuff to add for content with Foundation
@mixin global-addons {
    // Image en bloc centré
    img.centered {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    // Etire proportionellement l'image à la largeur de son conteneur
    img.expanded {
        width: 100%;
        height: auto;
    }
}



//Calc plugin
@mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

//translate plugin
@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -o-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    transform: translate($x, $y);
}

// Put a triangle icon as a pseudo element
@mixin arrow_after($color: black,$width: 20) {
    position: relative;
    margin-bottom: $width - 1 + px;
    &:after {
        content: '';
        position: absolute;
        right: 50%;
        top: 100%;
        @include transform(translate(50%, 0%));
        z-index: 20;
        width: 0;
        height: 0;
        border-right: $width + px solid transparent;
        border-left: $width + px solid transparent;
        border-top: $width + px solid $color;
    }
}

// Some text shadow for a link
@mixin active_link {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

// Build a little thin line
@mixin hline {
    display: block;
    height: 2px;
    border: 0;
    margin: 0 auto;
    padding: 0;
    background-color: rgba($black,0.2);
    width: 30%;
    text-align: center;
    margin-bottom: 1rem;
}

// Common transparent BG
@mixin bg_transparent {
    background-color: rgba(0, 0, 0, 0);
}

// Unuseful mixin to use box-shadow with deprecared vendor prefixes..
@mixin box-shadow-more($top, $left, $blur, $color, $inset: "") {
    -webkit-box-shadow: $top $left $blur $color #{$inset};
    -moz-box-shadow: $top $left $blur $color #{$inset};
    box-shadow: $top $left $blur $color #{$inset};
}

@mixin svgnor {
    #black {
        fill: $black;
        * {
            transition-property:fill;
            transition-duration:0.2s;
            transition-timing-function:ease-in;
            fill: $black;
        }
    }
}

////*svg images*/
@mixin svginvers {

}
