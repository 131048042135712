.curtain {
  display: flex;
  align-items: stretch;
  width:100%;
  background-color: transparent;
  align-content: stretch;
  //box-shadow: 0 0 32px rgba(0,0,0,0.2) ;
  border:transparent 1px solid;
  z-index:10;
  > .panel {
    background-color: $black;
    height:60vh;
    position:relative;
    width:100%;
    border-right: 1px solid #FFF;
    transition: all 0.6s ease;
    transition-property: width;
    will-change:width;
    &.big {
      width:166%;
    }
    > .imgbg {
      background-size: cover;
      background-position:center;
      transition: all 0.2s ease;
      transition-property: transform;
      position: absolute;
      min-height:100%;
      min-width:100%;
      opacity: 1;
      z-index:10;
      overflow:hidden;
      > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
        max-width: none;
        max-height: 100%;
        transition: filter 0.2s linear;
        filter: blur(0px);
        @media #{$xlarge-up} {
          min-height: 100%;
          min-width: 100%;
          max-width: none;
          max-height: none;
        }

      }
      &:after {
        content:'';
        background-image:linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4));
        position: absolute;
        z-index:1;
        height:100%;
        width:100%;
        transition: all 0.2s ease;
        transition-property: backgroun-image;
      }

    }

        h2 {
          z-index:20;
          transition: color 0.2s linear;
          top: 50%;
          left: 50%;
          position: absolute;
          width: 10px;
          text-align: center;
          height: 10px;
          white-space: nowrap;
          font-size: 2.6rem;
          text-transform: uppercase;
          font-weight: 900;
          color: rgba(240,240,240,0.9);
          &.textVert {
            transform: rotate(-90deg) translate(-5px,-5px);
            transform-origin: 50% 50%;}
          span {
            transform: translate(-50%,-50%);
            text-align: center;
            position:absolute;
          }
        }

    &.animate {
      &:hover {
        z-index:20;
        width:134%;
        &.big {
          width:186%;
        }
        @media #{$xlarge-up} {
          width:117%;
          &.big {
            width:186%;
          }
        }
        &:after {
          content:'';
          background: rgba(0,0,0,0.3);
          height:10px;
          border-radius:100%;
          filter:blur(3px);
          position: absolute;
          width:120%;
          display:block;
          bottom:-20px;
          left:-10%;

        }
        h2 {

          color: rgba(240,240,240,0.9);
        }
        > .imgbg {
          opacity:1;
          filter: grayscale(80%);
          border:2px solid #FFF;
          transform:  scale(1.08);
          &:after {
            content:'';
            background-image:linear-gradient(rgba(30, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
          }
          @media #{$xlarge-up} {
            transform:  scale(1.04);
          }
          > img {

            filter: blur(4px);
          }


        }
      }
    }
  }
  &.verticalCurtain {
    flex-direction: column;

    @include breakpoint(medium down) {
      height: 740px !important;
    }
    > .panel {
      background-color: $black;
      height:100%;
      position:relative;
      width:100%;
      right: 0px solid #FFF;
      border-bottom: 1px solid #FFF;
      border-right:0;
    }
  }
}



// curtain for visit-virtuelle

.curtain.visite-vituel {

  flex-direction: column;
  @include breakpoint(large up) {
    flex-direction: row;

  }
  svg {
    float: left;
  }
  > .panel {
    background-color: $highlight-gray;
    height:initial;
    &:last-child {
      border:0px;
    }
    &.verticalCurtain > .panel{
      height: initial;flex-grow:1;
      background-color: $black;
      @include breakpoint(large up) {
        height: 100%;
      }
      &:last-child {
        border:0px;
      }


        h2 {
          color: #FFF;
        }

      &#visit-navigation{
        flex-grow: 0.5;
        background-color: $highlight-gray;
        @include breakpoint(medium down) {
            height:96px;
          flex-grow: initial;
        }

        .dropdown.menu > li.is-dropdown-submenu-parent > a {
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
        }
      }
    }
    .imgbg {
      opacity: 0.7;
      background-color: $black;
      height: 100%;
      width: 100%;
      position: absolute;
      background-size: cover;
      &:after {
        display:none;
      }
    }
    &.big {
      width:100%;

      @media #{$large-up} {
        width:130%;}

      @media #{$xlarge-up} {
        width:130%;}

      @media #{$xxlarge-up} {
        width:110%;}
    }
    &.curtain {
      width:100%;

      @media #{$large-up} {
        width:70%;}

      @media #{$xlarge-up} {
        width:70%;}

      @media #{$xxlarge-up} {
        width:90%;}
    }
  }
}

//#visit-navigation

#visit-navigation  {
  background-color: $highlight-gray;
  position:relative;
  h4 {
    color:$red;
    font-weight: 800;
    //margin: 1rem;
  }
  >div {
    position:absolute;
  }
}

//team presentation

.panel-infos{
  font-weight:200;
  strong {
    font-weight:300;
  }
  .team-content {
    padding: 1rem;
    h2 {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
    h3 {
      margin-bottom: 1rem;
    }
    h5 {
      margin-bottom: 0.72rem;
    }
    float: left;
    width: 100%;
    border:10px solid $light-gray;
  }
}


.presentation {
  p {color:#6b6b6d;}
  h2 {
    font-size:2.75rem;
    font-weight: 600;
    padding-top:2rem;
    padding-bottom:1.4rem;
    border-top:10px solid $medium-gray;
  }
  h4 {
    margin-bottom:1rem
  }
  hr {
    width:50%;
    margin-left:25%;
    background: $medium-gray;
    border-color:$medium-gray;
  }
  &+.curtain {
    position: relative;
    float:left;
    margin-top:3rem;
    &:before {
      width:100%;
      content:'';
      position: absolute;
      top:-2rem;
      display:block;
      border-top:10px solid $medium-gray;}
  }
}



.visite_footnav {
  display: block;
  list-style-type: none;
  margin: 0;

  @media #{$large-up} {
    display: flex;
  }
  li {
    width:100%;
    text-transform:uppercase;
    font-size:1.4rem;

    a {
      text-transform:uppercase;
      color:$black;
    }

    &.active {
      a {
        color: $red;
      }
    }
  }
}


.svgcontent {
  position: relative;

  svg {
    path {
      fill-opacity: 0.5;
      stroke-opacity: 0.7;
      stroke-width: 2px;
    }

    a {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  #overlay {
    opacity: 0;
  }

}

.icon-visitmore1 {
@extend .icon-plan
}
.icon-visitmore2 {
  @extend .icon-calendar

}
.icon-visitmore3 {
  @extend .icon-museummore

}

#visit-footer {
  position: absolute;
  transform-origin: 0 100%;
  transform: rotate(-90deg) translate(0%, 100%);
  background: #000;
  bottom: 0;
  left: 0px;
  padding: 0 0.4rem ;

  font-size: 1rem;
}
.curtain > .panel h2  {
  border:0px;
  span.titlepanel {
    font-size: 1.3rem;
    transform: translate(-50%, 100%);
  }
}

.open-eyed-visit:before{
    content: "\f06e";
    font-family: icomoon;
}


.close-eyed-visit:before {
    content: "\f070";
    font-family: icomoon;
}
