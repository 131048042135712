/*Fonts library*/
$icomoonPath: "../fonts" !default;

/*Fonts library*/
@font-face {
    font-family: 'icomoon';
    src:  url('#{$icomoonPath}/icomoon.eot?7qqjds');
    src:  url('#{$icomoonPath}/icomoon.eot?7qqjds#iefix') format('embedded-opentype'),
      url('#{$icomoonPath}/icomoon.woff2?7qqj2s') format('woff2'),
      url('#{$icomoonPath}/icomoon.ttf?7qqj2s') format('truetype'),
      url('#{$icomoonPath}/icomoon.woff?7qqj2s') format('woff'),
      url('#{$icomoonPath}/icomoon.svg?7qqj2s#icomoon') format('svg');
    font-weight: 400;
    font-style: normal;
}


/*
 * Default usage, for self closed element like in <i class=".."></i>
 */
.iconcont,[class^="icon-"]:not(p):not(div):not(h1):not(h2):not(h3):not(h4),
[class*=" icon-"]:not(p):not(div):not(h1):not(h2):not(h3):not(h4) ,[class^="icon-"]:before,[class*=" icon-"]:before{
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    // Better Font Rendering
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // Font sizes (icon-2x = 2em, icon-3x = 3em, etc..)
    @include font-multiplier(2 3 4 5, $selector-prefix: "icon");

    &.icon-block{
        display: block;
    }
}


/*
 * Icon insert in a container
 *
 * Contrary to the default selector, this does not perturb container
 * elements font, so it's safe to have text in container
 */
p,div,h1,h2,h3,h4,* {
    &[class^="icon-"],
    &[class*=" icon-"]{
    &:before{
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        // Better Font Rendering
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @include font-multiplier(2 3 4, $selector-prefix: "icon", $selector-pseudo: "before");

    // Icon forced as a block element
    &.icon-block{
        &:before{
            display: block;
        }
    }

    &.icon-corner-left{
        position: relative;
        // Get some place for icon
        padding-left: 1em;
        @include font-multiplier(2 3 4, $selector-prefix: "icon", $property: "padding-left");

        // Simple position for icon
        &:before{
            display: block;
            position: absolute;
            top: 0.1em;
            left: 0;
        }

        // Some tiny divider between icon and all elements
        &>*{
            margin-left: rem-calc(5px);
        }
    }
    &.icon-center-left{
        position: relative;
        // Get some place for icon
        padding-left: 1em;
        @include font-multiplier(2 3 4, $selector-prefix: "icon", $property: "padding-left");

        // Simple position for icon
        &:before{
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            @include translate(0,-50%)
        }

        // Some tiny divider between icon and all elements
        &>*{
            margin-left: rem-calc(5px);
        }
    }
}
}

// Prepend text button with an icon from a class (without <i class="">)
.button[class^="icon-"], .button[class*=" icon-"] {
    font-family: inherit;
    &:before {
        font-family: 'icomoon';
        margin-right: rem-calc(5px);
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    &.icoAft {
        &:before {
            position: reltive;
            right: 0.5rem;
            width: 2rem;
        }
    }
}

// Icon Sizes
.icon-2x{
    font-size: 2rem;
}
.icon-3x{
    font-size: 3rem;
}
.icon-4x{
    font-size: 4rem;
}
// Import icon map part
@import "components/_icomoon_icons";
