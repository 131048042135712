table{
    &.expand{
        width: 100%;
    }
}

/*
 * Simulating table
 */
.table {
    display: block;
    width: 100%;
    
    &> .tablerow {
        text-align: center;
        width: 100%;
        @media #{$medium-up} {
            display:flex;
            justify-content:space-between;
        }
        &> .coltable {
            width: auto;
            display: inline-block;
            white-space: nowrap;
            padding: 0.6rem;
            
            a {
                font-size: 0.74rem;
                font-weight: 400;
                text-transform: uppercase;
            }
        }
    }
}


/** tableau responsive **/
.responsive-table {
    width: 100%;

    tr:nth-child(2n+2) {
        background-color: #F7F7F7;

    }
    th {
        display: none;
    }
    th, td {
        text-align: left;
    }
    th:first-child, td:first-child {
        border: none;
    }

    td {
        display: block;
    }

    td:first-child {
    }

    td:last-child {
        padding-bottom: .5em;
    }
}

.responsive-table td:before, .deux_axes td:before {
    content: attr(data-th) " ";
    font-weight: bold;
    width: 100%;
    display: inline-block;
}



.deux_axes td:nth-child(2n+2) {
    background-color:#F7F7F7;
    padding: 0.8em;
    margin: 0;
}


tr.titre_table th {
    display: block;
}

/* =============================================================================
   A partir de 980px
========================================================================== */
@include breakpoint(large up) {
    .responsive-table {
        th, td {
            display: table-cell;
        }
        td:before {
            display: none;
        }
    }




    tr.titre_table {
        border-top:none;
        th {
            display: table-cell;
        }
    }
}