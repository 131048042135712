.list-group {
  a {
    display:block;
    float: left;
    border:$dark-gray 1px solid ;
    background: $light-gray;
    color:$black;
    padding: 0.2rem;
    margin-top:0.4rem;
    clear:both;
  }
  margin-top:0.4rem;
}