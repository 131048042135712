//in testimonials

$baseHeight: 264;
$basemargin: 16;

$itemsHeight: (
        "small": 264,
        "medium": 324,
        "large": 324,
        "xlarge": 384,
        "retina": 464,
);

$iExpoHeight: (
        "small": 264,
        "medium": 324,
        "large": 324,
        "xlarge": 384,
        "retina": 464,
);

$bigHeight: (
        "small": 528,
        "medium": 648,
        "large": 648,
        "xlarge": 768,
        "retina": 928,
);

.wallgrid-item {
  .panel {
    margin-bottom: 0;
  }
}

/* ---- grid ---- */

.grid {
  margin: #{$basemargin/2}px;
  &#container {
    float:left;
    width:calc(100% -  #{$basemargin}px ) ;
  }

  /* ---- grid sizer width---- */
  .grid-item {
    @include grid-column(12 );
    @include grid-col-collapse();

    @include breakpoint(medium) {
      @include grid-column(6 );
      @include grid-col-collapse();

    }
    @include breakpoint(large) {
      @include grid-column(4 );
      @include grid-col-collapse();

    }
    @include breakpoint(xlarge) {
      @include grid-column(3 );
      @include grid-col-collapse();

    }
  }

}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

// responsive height
@mixin respHeight($arrayheight: $itemsHeight) {
  @each $height, $height_value in $arrayheight {

    @include breakpoint(#{$height} up) {
      height: #{$height_value}px;

      figure {
        height: #{($height_value - $basemargin)}px;
      }
      &.type1, &type4 {
        height: #{$height_value}px;
      }
      &.type2, &.type3 {
        height: #{($height_value * 2)}px;
      }

      &.type2 {
        .infoCont {
          position: absolute;
          top: #{($height_value)}px;
        }

      }
      .infoCont {
        padding: #{($height_value / 24)}px #{($height_value / 12)}px;
      }
    }

  }
}

// generic item style
.grid-item {
  @include respHeight($itemsHeight);
  float: left;
  border: 0;
  position: relative;

  .contentGrid {
    overflow: hidden;
    position: absolute;
    margin: #{$basemargin/2}px;
    background: $light-gray;
    @include calc('width', '100% - 1rem');
    @include calc('height', '100% - 1rem');
  }
  .infoCont {
    position: absolute;
    z-index: 2;

  }
  h2 {
    font-size:1rem;
    @include breakpoint(medium up) {
      font-size:1rem;

    }
    @include breakpoint(large up) {
      font-size:1.10rem;
    }
    @include breakpoint(xxlarge up) {
      font-size: 1.20rem;
    }
  }
  h5 {
    //font-size: 0.875rem;
    &:after,&:before{display: none}

  }

  p.subtitle {
    display: table;
    background: #000000;
    padding: 0 0.3rem;
    color: #fff;
    font-weight: 300;
    font-size: 1rem;
    text-transform: none;
  }

  .desc {
    font-weight: 200;
    //font-size: 0.875rem;
  }
  figure {
    margin: 0;
    position: absolute;
    overflow: hidden;
    width: 100%;
    img {
      position: absolute;
      left: 50%;
      min-width: 100%;
      top: 50%;
      @include translate(-50%, -50%)
    }
  }
  &.typeExpo {
    @include respHeight($iExpoHeight);
    .shortdescc {
      top: 65%;
      position: absolute;
      font-weight: 200;
      //font-size: 0.84rem;
      padding-top: 0.42rem;
      line-height: 1.2;
    }
    .figuretoExp {
      width: 100%;
      height: 65%;
      position: absolute;
      top: 0;
      left: 0;
      background: #000;

      figure {
        height: 100%;
        width: 100%;
        display: block;
      }
    }
    .contentGrid {
      background: transparent
    }
  }

  &.type3, &.type4 {
    figure {
      display: none;
    }
  }

  &.withCont {
    position: absolute;
    top: 50%;

    figure {
      height: 50%;
    }

    .shortdesc {
      font-weight: 300;
    }
  }

  // &.video,&.audio,&.sound,&.typeImage,&.image{

  //   @extend .picto-video;
  //   .contentGrid  {
  //     > a {
  //       &:before {
  //         content: "\f03d";
  //         color:#FFF;
  //         position:absolute;
  //         font-family: icomoon;
  //         //font-size:1rem;
  //         top:0;
  //         right:0;
  //         border-radius:0%;
  //         height:3rem;
  //         width:3rem;
  //         line-height: 3rem;
  //         text-align: center;
  //         overflow: hidden;
  //         display: block;
  //         background: $black;
  //         z-index: 2;
  //       }
  //     }
  //   }
  // }
  &.picto-video {

    .contentGrid  {
      > a {
        &:before{
          color:#FFF;
          position:absolute;
          content: "\f008";
          font-family: icomoon;
          top:0;
          right:0;
          border-radius:0%;
          height:3rem;
          width:3rem;
          line-height: 3rem;
          text-align: center;
          overflow: hidden;
          display: block;
          background: $black;
          z-index: 2;
        }
      }
    }
  }
  &.picto-sound {

    .contentGrid  {
      > a {
        &:before{
          color:#FFF;
          position:absolute;
          content: "\e912";
          font-family: icomoon;
          top:0;
          right:0;
          border-radius:0%;
          height:3rem;
          width:3rem;
          line-height: 3rem;
          text-align: center;
          overflow: hidden;
          display: block;
          background: $black;
          z-index: 2;
        }
      }
    }
  }
  &.picto-img {

    .contentGrid  {
      > a {
        &:before{
          color:#FFF;
          position:absolute;
          content: "\f03e";
          font-family: icomoon;
          top:0;
          right:0;
          border-radius:0%;
          height:3rem;
          width:3rem;
          line-height: 3rem;
          text-align: center;
          overflow: hidden;
          display: block;
          background: $black;
          z-index: 2;

        }
      }
    }
  }
  &.picto-music {

    .contentGrid  {
      > a {
        &:before{
          color:#FFF;
          position:absolute;
          content: "\f001";
          font-family: icomoon;
          top:0;
          right:0;
          border-radius:0%;
          height:3rem;
          width:3rem;
          line-height: 3rem;
          text-align: center;
          overflow: hidden;
          display: block;
          background: $black;
          z-index: 2;

        }
      }
    }
  }
  &.picto-presse {

    .contentGrid  {
      > a {
        &:before{
          color:#FFF;
          position:absolute;
          content: "\f1ea";
          font-family: icomoon;
          top:0;
          right:0;
          border-radius:0%;
          height:3rem;
          width:3rem;
          line-height: 3rem;
          text-align: center;
          overflow: hidden;
          display: block;
          background: $black;
          z-index: 2;

        }
      }
    }
  }
  &.picto-text {

    .contentGrid  {
      > a {
        &:before{
          color:#FFF;
          position:absolute;
          content: "\f0f6";
          font-family: icomoon;
          top:0;
          right:0;
          border-radius:0%;
          height:3rem;
          width:3rem;
          line-height: 3rem;
          text-align: center;
          overflow: hidden;
          display: block;
          background: $black;
          z-index: 2;
        }
      }
    }
  }

  &.big {
    @include respHeight($bigHeight);
    @include grid-column(12);
    @include breakpoint(medium) {
      @include grid-column(12 );
      @include grid-col-collapse();

    }
    @include breakpoint(large) {
      @include grid-column(8 );
      @include grid-col-collapse();

    }
    @include breakpoint(xlarge) {
      @include grid-column(6 );
      @include grid-col-collapse();

    }

    padding:0;
  }

}

/// css for type content
$padding: 0.5rem;
$shadow: $black;

.type1 {

  .infoCont {
    position: absolute;
    bottom: 1rem;
    line-height: 1.24;
    padding: 0 !important;
  }
  .tagtitle {
    display: block;
    float: none;
    width: 100%;
    line-height: 1.6;
  }

  &.withCont {
    .infoCont {
      position: absolute;
      bottom: 36%;
    }
  }

  h5 {
    margin: 0;
    display: block;
    float: none;
    color: $primary-color;
    span {
      line-height: 1.4;
      background:$white;
      padding: $padding;
      padding-top: 0;
      padding-bottom: 0;
      -webkit-box-decoration-break: clone;
      -ms-box-decoration-break: clone;
      -o-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }
  h2 {
    //font-size: 1rem;
    display: inline;
    background: $shadow;
    color: white;
    font-weight: 800;
    text-transform: none;
    line-height: 1.31;
    padding: $padding;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-decoration-break: clone;
    -ms-box-decoration-break: clone;
    -o-box-decoration-break: clone;
    box-decoration-break: clone;
    a {
      color: white;
    }
  }
  .desc, .shortdesc {
    display: none;
  }
}

.type2 {
  .contentGrid {
    border-bottom: 4px solid $highlight-gray;
  }
  .infoCont {

    line-height: 1.24;
  }
  .tagtitle {
    display: block;
    float: left;
    width: 100%;
    line-height: 1.6;
  }
  h5 {
    margin: 0;
    line-height: 1.6;
    text-decoration: underline;

  }
  h2 {
    //font-size: 1.125rem;
    line-height: 1.125;
    text-transform: none;
    font-weight: 200;

  }
  .shortdesc {
    display: none;
  }
}

.type3, .type4 {
  .contentGrid {
    border-bottom: 4px solid $highlight-gray;
  }
  .infoCont {

    line-height: 1.24;
  }
  .tagtitle {
    display: block;
    float: left;
    width: 100%;
    line-height: 1.6;
  }
  h5 {
    margin: 0;
    line-height: 1.6;
    text-decoration: underline;

  }
  h2 {
    //font-size: 2rem;
    line-height: 1.125;
    text-transform: none;
    font-weight: 100;

  }

  .desc, .shortdesc {
    display: block;
  }

}

.type4 {

  .shortdesc {
    display: none;
  }
}

.grid-item--width2 {
  width: 200px;
}

.typeExpo {
  @extend .type1;
  .desc, .shortdesc {
    display: block;
  }
  &.withCont {
  .infoCont {
    position: absolute;
    bottom: 50% ;
    width:100%;
    .desc {
      display:none;
    }
    .shortdesc {
      margin-top:1rem;
      //font-size: 0.8rem;
      position: absolute;
      width: 100%;
      top:100%;
    }
  }
  }

}

/// controls
#controls, #controls_like {

  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  float: left;
  clear: both;
  width: calc(100% - 2rem);
  a {
    background: $black;
    color: $white;
    text-transform: uppercase;
    margin-right: 0.25rem;
    //font-size: 0.7rem;

    @include breakpoint(medium) {
      //font-size: 1rem;
    }
    &.is-checked {
      background-color: #dd3333;
    }
  }
  .menu {
    li {
      @include breakpoint(medium down) {
      display: block;}
    }
  }
  [data-filter="none"] {
    display: none;
  }
}


#themeControls, #controls_like {
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1rem;
  float: left;
  clear: both;
  width: calc(100% - 2rem);
  a {
    background: $black;
    color: $white;
    text-transform: uppercase;
    margin-right: 0.25rem;
    //font-size: 0.7rem;

    @include breakpoint(medium) {
      //font-size: 1rem;
    }
    &.is-checked {
      background-color: #dd3333;
    }
  }
  .menu {
    @include breakpoint(large ) {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      li {
        display: flex;
        margin-bottom: 4px;
        a {
          display: flex;
          align-items: center;
        }
      }
    }

    li {
      @include breakpoint(medium down) {
      display: block;}
    }
  }
  [data-filter="none"] {
    display: none;
  }
}
