.fifteen {
  @include grid-row(15) {
    .grid-item {
      @include grid-column-position(center);
    }
    .fifteen1 {
      // 5/16 = 31.25%
      @include grid-column(1);
    }
    .fifteen3 {
      // 11/16 = 68.75%
      @include grid-column(5);
      @include breakpoint(large) {
        @include grid-column(3);
      }
    }
  }
}