#timeScroll {
  width: 42px;
  @media #{$large-up} {
    width: 82px;
  }
  position: sticky;
  top: 0;
  left: 0;
  background: $black;
  padding: 0;
}

#timeScrollbar{
  height: calc(100vh);
  width: 100%;
  text-align: center;
  position: relative;left:0%;margin-top:0rem;
}

#totScrollbar{
  height: 100%;
  width: 2px;
  top:-00%;
  text-align: center;
  background: $light-gray;
  z-index: -1;
  position: absolute;left:50%;transform: translate(-50%,0);
}



////
$background: $black;
$subtle: $black;
$h1: #DB5B33;
$h2: #4598B5;
$body: #6B7072;
$dark: #222;

#dotScrollbar {
  display: none;
  @media only screen and (min-width: 650px) { display: block; }
  margin: 0em 0;
  height:100%;
  .shim {
    display: none;
    width: 100%;
  }

  .holder {
    position: relative;
    font-size: 85%;
    @media only screen and (min-width: 750px) { font-size: 90%; }
    @media only screen and (min-width: 900px) { font-size: 95%; }
    padding: 0em 0 0 0;
    background-color: $background;

    .bar {
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 2px;
      left: 50%;
      background-color: $subtle;

      .indicator {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $red;
        width: 2px;
      }

    }

    .labels {
      //max-width: 1280px;
      margin: 0 auto;
      text-align: center;
      height:100%;
      padding: 0 0em;
      @media #{$large-up} {
        padding: 0 2em;
      }
      i {
        display: block;
        position: relative;
        float: left;
        cursor: pointer;
        width:100%;
        &::before {
          z-index: 2;
          position: absolute;
          bottom: 50%;
          left: 50%;
          display: block;
          content: '';
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          background-color: $light-gray;
          transform: translateX(-50%) translateY(50%);
          transition:
                  border-color 100ms ease-in,
                  background-color 150ms ease-in;
        }

        &::after {
          z-index: 1;
          display: block;
          content: attr(data-label);
          position: absolute;
          top: 50%;
          right:0rem;
          padding: 0.375rem;
          transform: translate(0,-50%) ;
          font-family: 'Inter';
          font-weight: 400;
          color: $white;
          background: $black;
          opacity: 0;
          padding-right:0;
          border-radius: 0.5rem;
          pointer-events: none;

          transition:
                  color 150ms ease-in,
                  opacity 150ms ease-in,
                  padding-right 100ms ease-out;
        }

        &:hover, &:focus {
          &::before {
            background-color: $red;
          }
          &::after {
            padding-right:2em;
            opacity: 1;
          }
        }

        &.read {
          &::before {
            background-color: $red;
          }
          &:hover, &:focus {
            &::before {
              background-color: $light-gray;
            }
          }
        }

        &.reading {
          &::after {
            background: $red;
            padding-right:2em;
          }
          &:hover, &:focus {
            &::after {
              padding-right:2em;
              opacity: 1;

            }
          }
        }

      }

    }

  }

  &.fixed {

    .holder {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 42px;
      @media #{$large-up} {
        width: 82px;
      }
      z-index: 1;
    }

    .shim {
      display: block;
    }

  }

}