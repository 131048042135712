// Bootstrap variables

$gray: #555;
$brand-primary: #ec8055;
$table-border-color: #ddd;
$grid-gutter-width: 30px;

// Mixins

@mixin cursorGrab() {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

@mixin noUserSelect() {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

// Styling

#table {
  $padding: 5px;
  $header-height: 34px;
  $dropdown-max-height: 300px;
  header {
    @include noUserSelect;
    margin-bottom: $grid-gutter-width / 2;
    .count, .spinner {
      display: inline-block;
      height: $header-height;
      line-height: $header-height;
      vertical-align: middle;
      cursor: default;
    }
    .pagination {
      margin: 0;
    }
  }
  .dropdown {
  position: relative;
  }
  .table-responsive {

    border: 1px solid #CCC;
    overflow: scroll;
    // FIXME: This is a cheap workaround to this issue:
    //        https://github.com/twbs/bootstrap/issues/11037
    min-height: $dropdown-max-height * 2;
    table {
      border: 1px solid $table-border-color;
      thead {
        @include noUserSelect;
        th {
          padding: 0;
          cursor: default;
          user-select: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          .flex {
            display: flex;
            flex-flow: row wrap;
            .column-name {
              padding: $padding;
              flex: 2 100%;
              text-align: center;
              font-size: 12px;
              text-align: center;
              white-space: nowrap;
              font-weight: 400;
              ~ .sortable, ~ .filter {
                border-top: 1px solid $table-border-color;
              }
            }
            .sortable, .filter {
              flex: 1 50%;
              cursor: pointer;
              text-align: center;
              &.disabled {
                display: none;
              }
            }
            .sortable.active, .filter.active .filter-button {
              background: $brand-primary;
              color: white;
            }
            .sortable {
              order: 1;
              padding: $padding;
            }
            .filter {
              order: 2;
              .filter-button {
                display: inline-block;
                width: 100%;
                height: 100%;
                padding: $padding;
              }
              .dropdown-menu {
                max-height: $dropdown-max-height;
                overflow-y: auto;
              }
            }
          }
        }
      }
      tbody {
        font-size: 12px;
        &.grabbable {
          @include cursorGrab;
          @include noUserSelect;
          &.grabbing {
            cursor: move;
          }
        }
        td {
          word-break: break-word;
          -moz-hyphens: auto;
          hyphens: auto;
        }
      }
    }
  }


  .dropdown {
    position: relative;
  }

  .dropdown-toggle {
    *margin-bottom: -3px;
  }

  .dropdown-toggle:active,
  .open .dropdown-toggle {
    outline: 0;
  }

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: top;
    border-top: 4px solid #000000;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: "";
  }

  .dropdown .caret {
    margin-top: 8px;
    margin-left: 2px;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    background-color: #ffffff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.2);
    *border-right-width: 2px;
    *border-bottom-width: 2px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
  }

  .dropdown-menu.pull-right {
    right: 0;
    left: auto;
  }

  .dropdown-menu .divider {
    *width: 100%;
    height: 1px;
    margin: 9px 1px;
    *margin: -5px 0 5px;
    overflow: hidden;
    background-color: #e5e5e5;
    border-bottom: 1px solid #ffffff;
  }

  .dropdown-menu > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 20px;
    color: #333333;
    white-space: nowrap;
  }

  .dropdown-menu > li > a:hover,
  .dropdown-menu > li > a:focus,
  .dropdown-submenu:hover > a,
  .dropdown-submenu:focus > a {
    color: #ffffff;
    text-decoration: none;
    background-color: #0081c2;
    background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
    background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
    background-image: -o-linear-gradient(top, #0088cc, #0077b3);
    background-image: linear-gradient(to bottom, #0088cc, #0077b3);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
  }

  .dropdown-menu > .active > a,
  .dropdown-menu > .active > a:hover,
  .dropdown-menu > .active > a:focus {
    color: #ffffff;
    text-decoration: none;
    background-color: #0081c2;
    background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
    background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
    background-image: -o-linear-gradient(top, #0088cc, #0077b3);
    background-image: linear-gradient(to bottom, #0088cc, #0077b3);
    background-repeat: repeat-x;
    outline: 0;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);
  }

  .dropdown-menu > .disabled > a,
  .dropdown-menu > .disabled > a:hover,
  .dropdown-menu > .disabled > a:focus {
    color: #999999;
  }

  .dropdown-menu > .disabled > a:hover,
  .dropdown-menu > .disabled > a:focus {
    text-decoration: none;
    cursor: default;
    background-color: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  }

  .open {
    *z-index: 1000;
  }

  .open > .dropdown-menu {
    display: block;
  }

  .dropdown-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 990;
  }

}

ul.pagination li.disabled.navigation {
  display: none;
}

article.tableau-statistic {
  h1:first-child {
    margin-top: 5rem;
    margin-bottom: 2rem;
    font-size: 3rem;
  }
}

.tooltip {

}
