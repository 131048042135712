/*
 *
 * Restyling and addons on Foundation forms
 *
 */
$flatform-label-width: 220px !default;

form{

    /*
     * Simple form with nicely stacked label+input
     */
    &.cool-stack{
        input,
        textarea,
        select{
            border-radius: 0.3rem;
        }

        label .asterisk{
            color: lignten($red,10%);
        }

        // Direct radio bullet list will be inline
        .inline-radio{
            &>ul{
                margin: 0;

                &>li{
                    display: inline-block;

                    &+li{
                        margin-left: 1rem;
                    }
                }
            }
        }

        .row{
            &+.row{
                margin-top: 1rem;
            }
        }

        .field-wrapper{
            label,
            .label{
                margin-bottom: 0.5rem;
            }

            &+.field-wrapper{
                margin-top: 0.5rem;
            }
        }
    }

    /*
     * Avoid to display required marks on fields
     */
    .discreet{
        label .asterisk{
            display: none;
        }
    }




}

/*
 * Clean flat form with label and input on the same baseline
 *
 * Both support react-form (smart-**) and crispy forms (holder)
 */
.flat-form{
    form,
    .wrapper{
        .smart-field,
        .holder{
            @include clearfix;
            // Field label
            label {
                display: inline-block;
                float: left;
                font-size: 1.2rem;
                font-weight: 400;
                color: $black;
                padding: 0.5rem 1.5rem 0.5rem 0.5rem;
                @media #{$medium-up} {
                    width: 33%;
                    text-align: right;
                    padding-right: 1.5rem;
                }
            }

            // Base styling for ALL input kind
            select,
            input[type="text"],
            input[type="password"],
            input[type="date"],
            input[type="datetime"],
            input[type="datetime-local"],
            input[type="month"],
            input[type="week"],
            input[type="email"],
            input[type="number"],
            input[type="search"],
            input[type="tel"],
            input[type="time"],
            input[type="url"],
            input[type="color"],
            textarea {
                float: left;
                font-size: 1.2rem;
                border-radius: 0.3rem;
                border: 1px solid $medium-gray;
                @media #{$medium-up} {
                    width: 33%;
                }

                &:focus {
                    border: 1px solid lignten($red,10%);
                }
            }

            // Position adjustment for radio
            input[type="radio"] {
                margin: 0.8rem 0.4rem 1rem;
            }

            select {
                height: 3rem;
            }

            // Required field mark
            &.smart-required {}

            // Field validation error for react-js
            &.smart-error{
                input {
                    border-color: red;
                }
                .error-message {
                    color: red;
                    font-style: italic;
                    margin-left: 1em;
                }
            }
            // Field validation error for crispy-form/abide
            &.error{
                select,
                input {
                    border-color: red;
                    // Get back margin lost in error kind
                    margin-bottom: rem-calc(16);
                }
                small.error{
                    margin-left: 1em;
                    float: left;
                    font-style: italic;
                    color: red;
                    background-color: transparent;
                }
            }

            // Mostly for react-js
            .inline-radio {
                float: left;
                label {
                    width: auto;
                    padding-right:0;
                }
            }

            // Mostly for crispy forms
            &.holder-inline-radio {
                @extend .clearfix;

                ul{
                    margin-left: 0;
                    float: left;
                    @media #{$medium-up} {
                        width: 33%;
                    }

                    li{
                        float: left;
                        list-style-type: none;
                        line-height: 1;

                        label {
                            width: auto;
                            padding-right: 0;
                            padding-bottom: 0;
                            line-height: 1;
                        }

                        input{
                            margin-bottom: 15px;
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .button-holder{
            padding: 1rem 0;
            text-align: center;
        }

        fieldset,
        .fieldset{
            margin: 0;
            padding: 2rem 2.5rem;
            background-color: $dark-gray;
            border: 0;

            fieldset {
                margin: 0;
                padding: 2rem 0rem;
            }
            .legend {
                margin: 0 0 2.5rem;
                font-size: 1.5rem;
                font-weight:600;
                color: $dark-gray;
                border-bottom: 1px solid $dark-gray;
                line-height: 1;
                padding-bottom: 5px;
            }

            &+fieldset,
            &+div{
                border-top: rem-calc(4px) solid $white;
            }
        }
        .gerance {
            fieldset {
                padding-bottom:4rem;
            }
        }
    }

    /* When there is a form without fieldset, need to fill bg */
    &.no-fieldsets{
        background-color: $dark-gray;
        border: 0;
    }
}

.fieldsetSearch {
    border:0;
    padding:0;
    legend {display:none}
}
