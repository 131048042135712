.conteneur {
  .article {
    border:1px solid #CCC;
    background: $white;
    margin-bottom:1rem;
    .article-top {
      position:relative;
      H4 {
        background: darken($dark-gray,20%);;
        color:#FFF;
        padding:0.75rem 1rem;
        margin: 0;
        a {
          color: $white;
        }
      }

      .metadonnees {
        @include breakpoint(medium up) {
          position:absolute;
          right:0;
          top:0;
        }
        margin:1rem;
        font-size: 0.75rem;
        .panel {
          border-radius: 0.25rem;

        }
      }
    }
  }
  .loadCont {
    padding: 1rem;
  }
  .conteneur-head {
    background:#FFF;border:1px solid #CCC;
    padding:1rem;
    margin-top:2rem;
    margin-bottom:1rem;
  }
  .container-down  {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

#selecteur {
  position: fixed;
  bottom:0;left:0;width:100vw;
  background:$red;
  z-index: 2;
  height:4rem;
  .input-group-label {
   background: transparent;
    color:$white;
    border:0;
    @include breakpoint(medium down) {padding:0rem;}
  }
  #id_date {
    width:auto;
    @include breakpoint(medium down) {width:6.4rem;}
  }
  .input-group {
   margin: 0.5rem;
  }
  .input-group-button {
    width: 100%;
    text-align: left;
  }
  a {
    color:$white;
  }
  .flex {
    align-items: center;
    display:flex
  }
}
