/*
 *
 * All stuff for footer
 *
 */

// footer CSS

.footer {
    float:left;
    margin:1rem;
    background-color: $highlight-gray;
    padding: 2rem 1rem;
    text-align: left;
    i {
        font-size: 100px;
    }
}

.footer {

    p, a {
        font-weight: 300;
        font-size: .8em;
    }

    h5 {
        &:before {
            display:none;
        }
    }
}

@media only screen and (min-width: 40.063em) {
    .footer {
        .columns:nth-child(n+2) {
            //border-left: 1px solid scale-color($white, $lightness: -30%);
            //min-height: 280px;
        }
    }
}

.footer-links {
    margin-left: 0.25rem;
    list-style-type: none;
    li {
        margin-top: .1em;
        a {text-transform: uppercase;}
    }
}

.prefooter {
    a:hover {
        color: $white !important;
    }
}

///prefooter
#prefooter {
    mix-blend-mode: multiply;
    position: relative;
    overflow: hidden;
    background: $black;
    
    &:after {
        content: image-url("theme/paper.png");
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        max-height: 100%;
        z-index: -1;
        opacity: 0.4;
    }
    
    .circleIco {
        & + p {
            color: $white;
        }
    }
    
    #preFooterIcons {
        .columns {
            margin-bottom: 0.6rem;
        }
        .more {
            p {
                font-size: 1rem;
                font-style: italic;
            }
        }
    }
}

footer {
    &>.section{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        
        p, li{
            font-size: 1.1rem
        }
    }
    
    // Logo/title
    .credits {
        p{
            margin: 0;
            font-size: 1.2rem
        }
    }
    
    // Short menu
    .menu {
        ul{
            margin: 0;
            @include breakpoint(large up) {
                float: right;
            }
            
            li{
                margin: 0;
                &>a{
                    // Restore inline mode instead of inline-list behavior
                    display: inline-block;
                    &:hover{
                        color: $white !important;
                    }
                }
                // Add some divider
                &+li{
                    padding-left: 1rem;
                    
                    &:before{
                        content: "|";
                        position: relative;
                        left: -0.5rem;
                    }
                }
            }
        }
        li {
            i {
                font-size:0.94rem;
            }
        }
    }
}

#contentFooter {
    H4 {
        min-height:4rem;
        strong {
        display: inline-block;
        float: left;
        }
    }
}
