.topMedia {
  width:100%;
  //height:66vw;
  max-height:75vh;
  position: relative;
  overflow: hidden;
}
.topMedia {
  .media{
    max-width:none;
    min-width:100%;
    max-height: none;
    min-height:100%;
    position: absolute;
    left:50%;
    top:50%;
    @include translate(-50%, -50%)
  }

}


.custom-flex-video-class {
  @include flex-video(709 by 400);
  position: relative;
  &:before {
    position: absolute;
    z-index: 1;
    display: block;
    content: '';
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(0,0,0,0.2);
  }
  iframe {
    @include breakpoint(large up) {
      top: 0;
    }
  }
}


.headerSlider.slick-slider {
  position: absolute;
  bottom: 0%;
  width:80%;
  left:50%;
  z-index: 1;
  @include translate(-50%,-40%);

  @include breakpoint(medium down) {
  .xlarge-25 {
    width:33%;
  }
  .medium-75 {
    width:66%;
  }
  }
  @include breakpoint(medium up) {
    @include translate(-50%, -30%);
  }
  @include breakpoint(large up) {

    min-height:50%;
    @include translate(-50%,-20%);

  }
  @include breakpoint(xlarge up) {
    min-height:60%;
    @include translate(-50%,-50%);
    top:50%;
    bottom:auto;
  }
  .slick-slide {
    .flex-grid {
      margin:4%;
      @include breakpoint(small down) {

        align-items: center;
        p {
          display: none;
        }
      }
    }
  }
  @include breakpoint(medium down) {
    width:100%;
  }
  *{color:$white};
  .slick-slide {
    @include breakpoint(medium down) {
      padding: 0rem;
    }
    padding: 0 4rem;
  }
  h2 {
    font-size: 4.75rem;
    font-weight:800;
    @include breakpoint(medium down) {

      font-size: 1.75rem;
    }
  }

  p {
    @include breakpoint(medium down) {

      font-size: 1.2rem;
    }
    font-size: 1.4rem;
    line-height: 1.2;
    font-weight: 500;
  }

  .slick-prev,.slick-next {
    top:50%;
    @include translate(0,-50%);
    left:0;
    position: absolute;
    z-index: 12;
    width:4rem;
  }
  .slick-next {
     right:0;
    left: auto;
   }

}

.language_choose {
  list-style-type: none;
  display: block;
  position: absolute;
  bottom:0rem;
  right:1rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  color:$primary-color;
  margin: 0;
  li {
    margin: 0;
    display: inline;
  }
  a{
    color: $white;
   &.active {
     color: $primary-color;
   }
  }
  .is-dropdown-submenu {
    min-width: 0;
  }
  .dropdown.menu {
  > li.is-dropdown-submenu-parent{float: left;
    > a {
  position: relative;
  display: block;
  float: left;}
  }
  }
}