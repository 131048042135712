
h3.year {
  width: 160px;
  border-bottom: 2px solid red;
}

.chronology {
  td {
    background:#FFF;
    vertical-align: top;
    &:first-child {
      font-weight: bold;
      padding-right: 10px;
      width: 160px;
    }
  }
  tr+tr td:last-child {
    border-top: 1px solid lightGrey;
  }

  p {
    margin-top: 5px;
  }
}
.simileTimeline {
  h1 {
    font-size:1.4rem;
    @include breakpoint(large up) {
      font-size:2rem;
    }
    @include breakpoint(xlarge up) {
      font-size:2.1rem;
    }
  }
 }