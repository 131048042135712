$menu-background: rgba(0,0,0,0.75);
$header-margin : 1rem;

.title-bar {
  background: $menu-background;
  padding: 0.9rem;
  @include breakpoint(large down) {
    height:5rem;
  }
  @include breakpoint(medium down) {
    height:5rem;
    background: $black !important;
  }
   .menu-icon {
     z-index: 10;
     zoom:2;
     margin-top:0.4rem;
   }
  .logo  {
    float:left;
    display:block;

    @include breakpoint(large down) {
      max-height: 100%;
      img {
        position: absolute;
        height:3.6rem;

      }
    }
  }
  .slogan  {
    float:left;
    display:block;

    @include breakpoint(large down) {
      max-width: 60%;
      margin-left:20%;
    }
    .title-4 {
      line-height: 1;
    }
  }
}
.title-bar-title {
  background: $black;
  position: absolute;
  right: 0;
  top: 0;
  @include breakpoint(large down) {
    border:10px solid $black;
  }
  @include breakpoint(medium down) {
    width:7rem;
    border:10px solid $black;
  }

}
.header {
  position:absolute;
  @include calc(width, "100% - ( #{$header-margin} * 2 )");
  margin:1rem;
  z-index: 2;
  @include breakpoint(medium down) {
    width:100%;
    position:relative;
    margin:0rem;
  }
  .menu.topbarmenu > li {
    &.is-dropdown-submenu-parent a::after {
      top: 70%;
    }
    @include breakpoint(large up) {
      display: block;
      float: left;
      vertical-align: top;
      >a {
        padding-left:0rem;
        padding-top: 1rem;
        @include breakpoint(large up) {
          padding-left:0.9rem;
          padding-top: 1.7rem;
        }
      }
    }
    &.menu-logo {
      padding: 0em;
      display:none !important;
      width:110px;
      @include breakpoint(large up) {
        display:block  !important;
      }
      @include breakpoint(large up) {
        width:auto;
      }
      a {
        padding-top: 0em;
        padding-bottom: 0em;
        padding-left:0.9rem;
      }
    }
  }
  .signature {
    float:left;
    padding:1rem;
  display:none;

    @include breakpoint(large up) {
      display:block;
      float: left;
      padding: 0;
      position: absolute;
      top: 3rem;
      left: 7.4rem;
    }
    @include breakpoint(large up) {
      float: left;
      padding: 1rem;
      position: absolute;
      top: 4rem;
      left: 10.2rem;
    }
    h1 {
      color: $white;
      text-transform: uppercase;
      font-weight: bold;
      line-height: 1;
      font-size: rem-calc(22);
      @include breakpoint(large up) {
        font-size: rem-calc(28);
      }
    }
  }
  .rightsearch {
    position:absolute;
    z-index: 2;
    right:1rem;
    top:1rem;
  }
  &.intern {
    background: $black;
    position: relative;
  }

  .title-bar {

    @include breakpoint(large down) {

      background: $black;
    }
  }
}
.top-bar {

  background: $menu-background;

  @include breakpoint(medium down) {
    .top-bar-left, .top-bar .top-bar-right {
      width: 100%;
    }
  }
  ul {
    background: transparent;
    &.menu {
      li {
        &.has-submenu {

          @include breakpoint(medium down) {
            position: initial;
            //width:90%;
          }
        }
        @include breakpoint(medium down) {
          display: block;
          //width:90%;
        }
        .is-submenu-item {
          background: $black;
        }
      }
      > li {
        text-transform: uppercase;
        position: relative;
      }
      .submenu {
        background: $black;

      }
    }
    li {
      background: transparent;

      a {
        color: #fff;
      }
    }
  }
}
#list-menu .is-drilldown {

  @include breakpoint(large down) {
    //@include calc(width, "100vw - ( #{$header-margin} * 2 )");
    max-width: none !important;
  }
  > .menu{

  @include breakpoint(large down) {
    @include calc(width, "100vw - ( #{$header-margin} * 2 )");
  }

  }
}
#list-menu,.signature {
  @include breakpoint(large up) {
    @include calc('width', '100% - 124px - 2rem')
  }
}
.menu-text {
  color: #fff;

  @media only screen and (max-width: 40em) {
    display: none !important;
  }
}

@media only screen and (min-width: 40em) {
  .menu:last-child {
    border-left: 1px solid #4e4e4e;
  }

  .menu:first-child {
    border-left: none;
  }

  #list-menu > .menu {
    > li:not(:last-child) {
     &:after {
       content:'';
       height:1rem;
       width:1px;
       background: $primary-color;
       right:0;
       top:0.75rem;
       position: absolute;
     }
    }
  }
}


.dropdown.menu .submenu {
  border: none;
}

.dropdown.menu .is-dropdown-submenu-parent.is-right-arrow>a::after {
  border-color: #fff transparent transparent;
}

.is-drilldown-submenu-parent>a::after {
  border-color: transparent transparent transparent #fff;
}

.js-drilldown-back::before {
  border-color: transparent #fff transparent transparent;
}


// .controls_like {
//   @extend .controls;
// }


///nav-tabs
.nav-tabs {
  margin-top:1rem;
  border: 1px solid $dark-gray;
  border-bottom:0;

   .tab {
     > a{
       font-size: 1rem;
    color: $white;
       &:hover {
         background: transparent;
       }
    }
     &.selectionned {
       >a {
       color:$primary-color
     }
     }
   }
}


//special inverse top bar for small

#header .top-bar {

    position:initial;
    display: block;
    .input-group {
      padding: 1rem;
      margin: 0rem;
      width:240px;
      input {
        max-width: none;
        margin: 0;
      }
    }

  @include breakpoint(medium down) {
    display: flex;
    position:relative;
    flex-direction: column-reverse;
    .language_choose{
      position: absolute;
      top: 1.4rem;
      z-index: 200;
      background: rgb(0, 0, 0) none repeat scroll 0% 0%;
      height: 2.6rem;
    }
  .input-group {
    padding: 1rem;
    margin: 0rem;
    width:100%;
    input {
      max-width: none;
      margin: 0;
      width:calc(100% - 6rem);
     }
    }
  }
}