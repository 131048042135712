@import "components/icomoon";

@mixin -emencia-list-arrow-type(
    $width: rem-calc(12px),
    $top-position: 0.75rem,
    $left-position: rem-calc(-2px),
    $font-size: null,
    $font-weight: null,
    $content: ">",
    $content-color: $red,
    $vertical-translate: true,
    $extended-icon: null
) {
    position: absolute;
    @if $top-position {
        top: $top-position;
    }
    left: $left-position;
    width: $width;
    font-family: icomoon;
    @if $font-size {
        font-size: $font-size;
    }
    @if $font-weight {
        font-weight: $font-weight;
    }
    @if $extended-icon {
        @extend .icon-arro_right
    } @else {
        content: $content;
    }
    color: $content-color;
    @if $vertical-translate {
        @include translate(0%, -50%);
    }
}


// Bullet list using arrow icon as bullet
ul.arrow{
    list-style-type: none;

    &:not(.defer){
        li {
            position: relative;
            padding-left: 1rem;

            &:before {
                @include -emencia-list-arrow-type;
            }
        }
    }
    // Defer arrow pseudo element to first direct child element of list items
    &.defer{
        li {
            position: relative;

            &>*:first-child{
                position: relative;
                padding-left: 1.5rem;

                &:before {
                    @include -emencia-list-arrow-type($top-position: 0.60rem);
                }
            }
        }
    }
}

/*
 * ul.arrow alike but with another arrow icon instead
 */
ul.arrow-2 {
    margin-left: 0;
    margin-right: 0;
    list-style-type: none;

    li {
        position: relative;
        padding-left: 1.2rem;

        &:not(.no-bullet):before {
            @include -emencia-list-arrow-type(
                $extended-icon: true,
                $content-color: black
            )
        }

        &+li{
            margin-top: 0.5rem;
        }
    }
}

/*
 * Use a chevron icon
 */
ul.chevron{
    list-style-type: none;

    &:not(.defer){
        li{
            position: relative;
            padding-left: 1.1em;

            &:not(.no-bullet) {

                &:before {
                    @include -emencia-list-arrow-type(
                        $extended-icon: true,
                        $top-position: null,
                        $vertical-translate: null,
                        $font-weight: 600
                    )
                }
            }
        }
    }
    // Defer arrow pseudo element to first direct child element of list items
    &.defer{
        li{
            &>*:first-child{
                position: relative;
                padding-left: 1.5em;
            }

            &:not(.no-bullet) {
                &>*:first-child{
                    &:before {
                        @include -emencia-list-arrow-type(
                            $extended-icon: true,
                            $font-weight: 600,
                            $top-position: 0.60rem
                        )
                    }
                }
            }
        }
    }

    // Bigger arrow and text
    &.bigger {
        &:not(.defer) li:not(.no-bullet),
        &.defer li:not(.no-bullet){
            font-size: 1.3em;
            &:before,
            &> *:first-child:before{
                font-size: 1.2em;
                top: 0.75rem;
            }
        }
    }
    &.smaller {
        li{
            //position: relative;
            padding-left: 1.1em;

            &:not(.no-bullet) {

                &:before {
                    @include -emencia-list-arrow-type(
                            $extended-icon: true,
                            $top-position: null,
                            $vertical-translate: null,
                            $font-weight: 600,
                            $content-color: $black
                    );
                }
            }
        }
    }
}

/*
 * List items divided by a bold border
 */
ul.divided,
ol.divided{
    list-style-type: none;

    li{
        &+li{
            margin-top: em-calc(10px);
            padding-top: em-calc(10px);
            border-top: em-calc(4px) solid $medium-gray;
        }
    }
}

/*
 * List items divided by a bold border and also border on top and bottom of
 * the list element
 */
ul.line-surround,
ol.line-surround{
    border-top: 4px solid $medium-gray;
    border-bottom: 4px solid $medium-gray;

    li{
        padding-top: 0.8em;
        padding-bottom: 0.8em;

        &+li{
            border-top: 4px solid $medium-gray;
        }
    }
}


/*
 * Nicely listed item with some minor vertical spaces and right padding
 * (mostly intended to use with flex lists)
 */
.nice-listing{
    margin: 0;
    padding: 0;
    &.gutter {
        margin: 1rem;
    }
    &>li{
        margin: 0 0 1rem !important;
        padding-right: 0.75rem;
    }
}


/*
 * Another list with arrow icon
 */
.arrowList {
    margin: 0;
    li {
        position: relative;
        color: $black;
        a {
            color: $black;
            margin-left: 0.2rem;
            display: inline-block;
            &:hover {
                color: $red;
            }
        }
        &:before {
            content: '';
            font-family: icomoon;
            font-size: 0.7rem;
            color: $red;
            position: absolute;
            left: 0;
            top: 0.2rem;
        }
    }
}

.listAndLine {
    list-style-type: none;
    border-top: 4px solid $medium-gray;
    margin: 0;
    margin-top: 1.5rem;
    float: left;
    display: inline-block;
    width: 100%;

    li {
        display: inline-block;
        width: 100%;
        margin: 0;
        border-bottom: 1px solid $medium-gray;
        padding-top: 0.84rem;
        padding-bottom: 0.84rem;
        a {
            color: $black;
        }
    }

    &.biglines {
        li {
            padding-top: 1.1rem;
            padding-bottom: 1.1rem;
            border-bottom: 4px solid lighten($medium-gray, 10%);
            &:last-child {
                border-bottom: 0px solid lighten($medium-gray, 10%);
            }
        }
    }
}

.bigArrow {
    li {
        position: relative;
        padding-left: 1.75rem;

        &:before {
            content: '>';
            font-family: icomoon;
            font-size: 1.2rem;
            position: absolute;
            top: 0.65rem;
            left: 0;
            color: $red;
        }
    }
}



.dotList {
    ul.dotted {
        margin: 0;
    }
    li {
        position: relative;
        color: $black;
        maring-left: 0;
        padding-left: 1rem;
        a {
            color: $dark-gray;
            margin-left: 0.2rem;
            display: inline-block;
        }
        &:before {
            content: '•';
            font-size: 1.4rem;
            color: $red;
            position: absolute;
            left: 0;
            top: 0rem;
            line-height: 0.9em;
        }
    }
}
.dotListsmall {
    ul.dotted {
        margin: 0;
    }
    li {
        position: relative;
        color: $black;
        maring-left: 0;
        padding-left: 0.7rem;
        a {
            color: $dark-gray;
            margin-left: 0.1rem;
            display: inline-block;
        }
        &:before {
            content: '•';
            font-size: 0.7rem;
            color: $black;
            position: absolute;
            left: 0;
            top: 0.5rem;
            line-height: 0.9em;
        }
    }
}

/* Just a list without bullet and margin */
.blocklist {
    margin: 0;
    list-style-type: none;
}




/* counter list */
ul.counterList {
    margin: 0;
    counter-reset: lic;
    list-style-type: none;
    li {
        position: relative;
        padding-left:2.4rem;
        counter-increment: lic;
        margin-top:0.2rem;
        margin-bottom:0.4rem;
        &:before {
            content:counter(lic);
            border-radius:50%;
            position:absolute;
            left:0;
            top:50%;
            width:1.6rem;
            height:1.6rem;
            line-height: 1.6rem;
            text-align: center;
            overflow: hidden;
            background: $dark-gray;
            color: $white;
            @include translate(0,-50%)
        }
    }
}
/* dashList */
.dash-list {
li {
    position:relative;
    padding-right:2rem;
    &:not(:first-child) {
        padding-left:2rem;
    &:before{
        content:'|';position:absolute;left:0;top:50%;transform: translate(0,-50%);
    }
    }
}
}

/* inline */
.inline-list {

    display: inline-block;
    li {
        display: inline;
    }
}

/* flexList */
.flex-list {

   // @include breakpoint(large up) {
        display: flex;
        li {
            width:100%;
            justify-content:space-between;
            text-align: center;
        }
    //}
}

//color list {
.blacklist {
    li {
        a {
            color: $black;
        }
    }
}
