/*Fonts library*/
$fontsPath: "../fonts/inter" !default;


/*Fonts library*/

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 100;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-Thin.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-Thin.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 100;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-ThinItalic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-ThinItalic.woff?v=3.15") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 200;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-ExtraLight.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-ExtraLight.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 200;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-ExtraLightItalic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-ExtraLightItalic.woff?v=3.15") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 300;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-Light.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-Light.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 300;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-LightItalic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-LightItalic.woff?v=3.15") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-Regular.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-Regular.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 400;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-Italic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-Italic.woff?v=3.15") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-Medium.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-Medium.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 500;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-MediumItalic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-MediumItalic.woff?v=3.15") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 600;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-SemiBold.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-SemiBold.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 600;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-SemiBoldItalic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-SemiBoldItalic.woff?v=3.15") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-Bold.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-Bold.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 700;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-BoldItalic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-BoldItalic.woff?v=3.15") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 800;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-ExtraBold.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-ExtraBold.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 800;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-ExtraBoldItalic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-ExtraBoldItalic.woff?v=3.15") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-Black.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-Black.woff?v=3.15") format("woff");
}
@font-face {
    font-family: 'Inter';
    font-style:  italic;
    font-weight: 900;
    font-display: swap;
    src: url("#{$fontsPath}/Inter-BlackItalic.woff2?v=3.15") format("woff2"),
    url("#{$fontsPath}/Inter-BlackItalic.woff?v=3.15") format("woff");
}


/*
 * Various content styles related to specific webapp parts (cms, blog,
 * accounts, etc..)
 *
 * For more common and global contents, prefer to put them in addons/
 *
 * Remember these style are enforced within #body_content so they will
 * override any basic style without an id
 */
audio,video {
    width:100%;
}
.right {
    float:right;
    width: auto;
}
.left {
    float: left;
}
.absoRight {
    position: absolute;
    top:0;
    right:0;

}
i {
    font-style:normal;
}
a:hover {
    .svg {
        @include svginvers;
    }
}

h1,h2,h3,h4 {
    //text-transform: uppercase;
}
h3 {
    font-weight: 200;
}

h5 {
    font-weight: 300;
    position:relative;
    &:before {
        content:'';
        background: $red;
        height:2px;
        width:1rem;
        bottom:-4px;
        left:0;
        display:block;
        position: absolute;
    }
}
.baseline svg {
    position: relative;
    top: 1rem;
    left: 0.4rem;
}

// NOTE: Should be moved to a text-red selector instead
*:not(.section.red) .red,
a.red {
    color: $red;
}


// Awful..
h1.article-title {
font-weight: 400 !important;
line-height: 1.1;
    font-family: 'Source Sans Pro', sans-serif;
border-bottom: 2px solid #CCC;
padding-bottom: 1rem;
    text-transform: normal;
    font-size: 2.3rem;
}

.headerAtticle,#visit-decription {
    h1 {

        font-weight: 400 !important;
        line-height: 1.1;
        font-family: 'Source Sans Pro', sans-serif;
        border-bottom: 2px solid #CCC;
        padding-bottom: 1rem;
        text-transform: normal;
        font-size: 2.3rem;
    }
    h4 {
        font-style: italic;
        text-transform: uppercase;
        font-style: italic;
    }

}
.h3 {
    @extend h3;
    display: block;
    padding-bottom: 2rem;
    padding-top: 2rem;
}

[data-link] {
    &:hover {
        cursor:pointer;
        H1,H2,H3,h4 {
            color:$red;
        }
    }
}

//nowarp
.nowarp {
    white-space: nowrap;
}
// type of hr
hr.dashed {
    border-style: dashed;
}

//figure align
//figure text arround

figure.left {
    float: left;
    margin-right: 1em;
}

figure.right {
    float: right;
    margin-left: 1em;
}

figure.intext {

    text-align: center;
    text-transform: uppercase;
    padding: .2em;
    margin: 0;


    img {
        max-width: 100%;
    }
}


//extra button
.button {
    &.white {
        background: $white;
        color:$black;
        border:1px solid $medium-gray;
    }
}
///agenda

.agenda {
    background-size: cover;
    background-attachment: fixed;
    background-position: center center;
    h2 {
        margin-bottom: 0;
        color: $white;
    }
}

#list {
    margin-top: -70px !important;
    p {
        font-size: 0.94rem;
        margin-top: -20px;
        color: #83837F;
    }
}

.cleallestsmall {
    @media #{$small-only} {
        clear: left;
    }
}

// Forces white writing on black background
.row {
    &.black {
        color: $white;
        background-color: $black;
    }
    &.on-black {
        color: $white;
        background-color: $black;
        h2, h3, h4, h5, h6, p, li {
            color: $white;
        }
    }
    // Forces black writing on white background
    &.white {
        color: $black;
        background-color: $white;
    }

    // Forces white writing on red background
    &.red {
        color: $white;
        background-color: $red;
    }
}

*.on-red {
    color: $white;
    background-color: $red;
    h2 {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 2.6rem;
    }
    h2, h3, h4, h5, h6, p, li {
        color: $white;
    }
}

.dark {
    color: $white;
    background-color: lighten($black,5%);
    h2, h3, h4, h5, h6, p, li {
        color: $white;
    }
}

.transparent {
    background: transparent;
}

.relative {
    position:relative;
}
/*
 * svg images
 * TODO: move into addons/svg, but actually doesnt works because of selector override somewhere else
 */
img.svg {
    display: none;
}

.svg {
    &.icone {
        &:not(.static) {
            &:hover,&.active,.active {
                @include svginvers;
            }
        }
    }
    &.inverse {
        #black {
            fill: $white;
            * {
                fill: $white;
            }
        }
        &:not(.static) {
            &:hover {
                @include svgnor;
            }
        }
    }
}

a.inverse {
    #black {
        fill: $white;
        * {
            fill: $white;
        }
    }
    &:not(.static) {
        &:hover {
            @include svgnor;
        }
    }
}

.paddertext {
    padding:3px;
}
/////////////////////////
// FOOTER
/////////////////////////



// footer CSS


//leftContent
.leftContent {
    .menu {
        border-left: 1px solid transparent;
        border-left: 1px solid transparent;
        & > li{
            > a {
             color:$red;
                text-decoration: none !important;
                &:hover {
                    color:$red;
                    text-decoration: none !important;
                }
            }
            &.active  {
                a {
                    background: transparent;
                    text-decoration: none !important;
                }
            }
        }
        ul {
            a {
                color:$black;
            }
        }
    }
}
.flexLargeCenter {
    @include breakpoint(large up) {
        display: flex;
        align-items: centrer;
    }
}

//flex down for large
.flexLargeEnd {
    @include breakpoint(large up) {
        display: flex;
        align-items: flex-end;
    }
}


// resize dot
ul.dot {
    list-style-type: none;
    margin-left: 1rem;

    li{
        position: relative;
        &:before {
        content: "•";
        font-size: 120%; /* or whatever */

        left:-10px;
            top:-4px;
        position:absolute;
        }
    }
}

.introFirst {
    .moreinfo {
        margin-bottom:1rem;
        border-bottom:2px solid $red;
        display:block;
        float:left;
    }
}
    .introSecond {
        display: none;
        float:left;
        clear: both;
    }

.cms-article .leftContent{
    p {
        margin:0;
        margin-top:1rem;
    }
}
.bibliographie {
     h4 {
         text-transform: none;

     }
 }

.dl-vid h4 {
  color: #666;
}

.fontEpi {
    font-family: "Epilogue","Helvetica Neue", Helvetica, Arial, sans-serif;
}
.text-stronger {
    font-weight: 900;
}

.v-space-normal {
    padding-top:5rem;
    padding-bottom:5rem;

}

video[poster]{
    width:100%;
    background-size: cover;
    object-fit: cover;
}
