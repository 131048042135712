/*
 * Flying slick plugin stylesheets
 */
.flyingslick-overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: rgba($black, 0.9);
    z-index: 3000;
    backdrop-filter: blur(5px);
    display:flex;
    flex-direction: column;
    justify-content: center;

    .flyingslick-close{
        position: absolute;
        top: 1rem;
        right: 1rem;
        margin: 0;
        padding: 1.5rem;
        border: 0;
        cursor: pointer;
        z-index: 300;

        &::after{
            content: "\e91d";
            font-family: 'icomoon';
            font-size: 2.5rem;
            color: $white;
            transition: 0.2s;
        }

        &:hover{
            &::after{
                font-size: 3rem;
            }
        }
    }
}

.flyingslick-album{
    flex: 1 0 100%;
    max-width: 100%;

    &.slick-slider.slick-initialized{
        .slick-prev,
        .slick-next{
            width: auto;
            height: auto;
            margin: 0;
            top: calc(50% - 2rem);
            position: absolute;
            z-index: 20;

            &:before{
                font-size: 1.8rem;
                line-height: 1;
                color: $whiter;
                opacity: 1;
                text-shadow: 0px 0px 15px #ffffff99;
            }
        }

        .slick-prev {
            left: 2rem;
            &:before {
                content: "\e91c";
            }
        }

        .slick-next {
            right: 2rem;
            &:before {
                content: "\e91b";
            }
        }


        .slick-list{
            display: flex;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .slick-slide{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                span{
                    display: inline-block;
                    position: relative;
                    background: transparent;
                    width: 98%;
                    text-align: center;
                    overflow-y: scroll;
                    max-height: 100vh;
                    &::before{
                        content: attr(data-name);
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        padding: 0.7rem 1rem;
                        font-size: 0.65rem;
                        color: $white;
                        text-transform: uppercase;
                        font-weight: 500;
                        @media #{$large-up} {
                            padding: 0.8rem 1.2rem;
                        }
                    }

                    img{display: inline-block;
                        transition: all 500ms ease;
                        //height: calc(63vw/2);
                    }
                }
                &:not(.slick-active) {
                    img{
                        height: calc(50vw/2);
                        opacity: 0.5;
                    }
                    span{
                      &::before{
                        display: none;
                      }
                    }
                }
            }
        }
    }

    .slick-dots{
        display:none !important;
        margin: 0;
        padding: 0;
        bottom: 1rem;
        @media #{$medium-up} {
            bottom: 5vh;
        }
        @media #{$large-up} {
            bottom: 10vh;
            padding: 0 15%;
        }

        li {
            position: relative;
            display: inline-block;
            height: 15px;
            width: 15px;
            margin: 0 0 3px;
            padding: 0;
            cursor: pointer;
            @media #{$medium-up} {
                margin: 0 0 5px;
            }

            & + li{
                margin-left: 3px;
                @media #{$medium-up} {
                    margin-left: 5px;
                }
            }

            button {
                display: block;
                height: 100%;
                width: 100%;
                line-height: 15px;
                font-size: 0.5rem;
                color: $black;
                padding: 0px !important;
                text-align: center;
                border: 1px solid $black;
                border-radius: 5rem;
                background: $black;

                // Disable unused before
                &:before {
                    position: static;
                    display: none;
                    content: "";
                    width: auto;
                    height: auto;
                    font-size: 0;
                    line-height: 0;
                }
            }

            &.slick-active{
                button {
                    color: $white;
                    border: 1px solid $black;
                    background: $white;
                }
            }
        }

    }
}
