@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "icomoon" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: black !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\e900" !default;
$slick-next-character: "\e901" !default;
$slick-dot-character: "\f150" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slickImageHeight: auto;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}


.reveal .close-button {

  height: 3rem;
  width: 3rem;
  border-radius: 100px;
  line-height: 1.1rem;
  background:#000;
  span {
    width: 3rem;
    display:inline-block;
    line-height: 1rem;
    color: #FFF;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 3rem;
    height:1.5rem;
    @include translate(-50%,-50%)
  }
}

/* Arrows */
.menu-gallery {
    a {
      font-size: 1rem;
      color:$black;
      &:hover {
        color: $red;
      }
    }

  .dropdown-pane {
    padding: 0;
    li {
      border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom:0;
    }}
  }

    .dropdown.menu   .first-sub {
    border: 1px solid #DDD;;
  }
}

.button.expo {
    background:$white;
    border:$dark-gray 3px solid ;
    position: relative;
  color: $black;
  text-transform: uppercase;
  padding-right: 4rem;
  padding-left: 0;
  text-indent: 1rem;
    &:after {
        position: absolute;
        right:0;
        top:0;
        height:100%;
        width:3rem;
        line-height: 100%;
        content: "+";
      border:$dark-gray 3px solid ;
      background:$dark-gray ;
      color:white;
      font-size: 2rem;
      line-height: 2rem;
      text-indent: 0rem;
    }
}

.galleryExpo {
    .descSlide {
        padding: 0 2rem 0 0;
    }
    .titleSlide {
        * {
            text-transform: none;
        }
        h3 {
            font-weight: 800;
        }
    }
    .slick-current {
        height: auto;
    }
    .slick-slide:not(.slick-current) {
        height:0;
    }
    .slider-for {
        height:$slickImageHeight;
        overflow: hidden;
        background: transparent;

        .slick-prev, .slick-arrow {
            top: 0;
            @include translate(0,0);
        }
    }
    .slider-nav {
        height:auto;
        background:transparent;
        img {
            margin:3%;
            max-width:96%;
            border:1px solid $medium-gray;
        }
    }
    .image-slider-container {
        height:$slickImageHeight;
        position: relative;
        text-align:center;
        a {
            width:100%;
            max-width: 100%;
            display:inline-block;
            position: relative;
            &:hover {
                &:after {
                    left:50%;
                    @include translate(-50%,-50%);
                    content: "\e911";
                    font-family: icomoon;
                    font-size: 2rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                    color: #FFF;
                    line-height: 2rem;
                    padding: 0.8rem;
                    display: block;
                    z-index: 100;
                    background: #000;
                    text-align: center;
                    vertical-align: middle;
                    border-radius: 100%;
                }
                &:before {
                    display: block;
                    content: '';
                    position: absolute;
                    z-index:1;
                    height:100%;
                    width: 100%;
                    background: rgba(0,0,0,0.5);
                }
            }
        }

        .image-slider {;
            position: relative;
            //top:50%;
            //left: 50%;
            //@include translate(-50%,-50%)
        }
    }

    .slick-prev,
    .slick-next {
        left: 0;
        z-index: 100;
        background: $white;
        padding: 0.3rem;
        height: 2.2rem;
        display: block;
        width: 2.2rem;

        position: absolute;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        color: transparent;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        border: none;
        outline: none;
        &:hover, &:focus {
            outline: none;
            color: transparent;
            &:before {
                opacity: $slick-opacity-on-hover;
                color:$red;
            }
        }
        &.slick-disabled:before {
            opacity: $slick-opacity-not-active;
        }
        &:before {
            font-family: $slick-font-family;
            font-size: 20px;
            line-height: 1;
            color: $slick-arrow-color;
            opacity: $slick-opacity-default;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }

    .slick-prev {
        left: 0px;
        [dir="rtl"] & {
            left: auto;
            right: 0px;
        }
        &:before {
            content: $slick-prev-character;
            [dir="rtl"] & {
                content: $slick-next-character;
            }
        }
    }

    .slick-next {
        right: 0px;
        left:auto;
        [dir="rtl"] & {
            left: 0px;
            right: auto;
        }
        &:before {
            content: $slick-next-character;
            [dir="rtl"] & {
                content: $slick-prev-character;
            }
        }
    }

    /* Dots */

    .slick-dotted.slick-slider {
        margin-bottom: 30px;
    }

    .slick-dots {
        position: absolute;
        bottom: -25px;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        li {
            position: relative;
            display: inline-block;
            height: 20px;
            width: 20px;
            margin: 0 5px;
            padding: 0;
            cursor: pointer;
            button {
                border: 0;
                background: transparent;
                display: block;
                height: 20px;
                width: 20px;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                padding: 5px;
                cursor: pointer;
                &:hover, &:focus {
                    outline: none;
                    &:before {
                        opacity: $slick-opacity-on-hover;
                    }
                }
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: $slick-dot-character;
                    width: 20px;
                    height: 20px;
                    font-family: $slick-font-family;
                    font-size: $slick-dot-size;
                    line-height: 20px;
                    text-align: center;
                    color: $slick-dot-color;
                    opacity: $slick-opacity-not-active;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
            &.slick-active button:before {
                color: $slick-dot-color-active;
                opacity: $slick-opacity-default;
            }
        }
    }

}
